<template>
    <div>
        <div v-if="getToken && getDataStatus" class="container">
            <!--  Staff info section          -->
            <div class="staff-info">
                <div class="button-list">
                    <a href="#" @click="() => onclickFilter('YESTERDAY')" v-bind:class="{'button-item': true, active: menu === 'YESTERDAY'}">Hôm qua</a>
                    <a href="#" @click="() => onclickFilter('THISMONTH')" v-bind:class="{'button-item': true, active: menu === 'THISMONTH'}">Tháng này</a>
                    <a href="#" @click="() => onclickFilter('LASTMONTH')" v-bind:class="{'button-item': true, active: menu === 'LASTMONTH'}">Tháng trước</a>
                </div>
                <div style="text-align: center">
                    <span class="staff-info__name">{{data ? data.staffName : ""}}</span>
                    <p class="staff-info__name--small">Skill Level {{data ? data.skillLevel : 0}} - Rank {{data ? data.currentRank:0}}/{{data ? data.totalRank:0}}</p>
                </div>
                <div class="staff-info__meta">
                    <div class="meta__item">
                        <span class="label">Rating TB</span>
                        <span class="content">{{data.rating ? data.rating.pointAverage : 0}}</span>
                    </div>
                    <div class="meta__item">
                        <span class="label">Tỉ lệ tích cực</span>
                        <span class="content">{{data.ratingGoodPercent ? data.ratingGoodPercent : 0}}%</span>
                    </div>
                </div>
                <div class="staff-info__meta">
                    <div class="meta__item">
                        <span class="label">Tỉ lệ CLPV</span>
                        <span class="content">{{data.serviceQualityPercent ? data.serviceQualityPercent : 0}}%</span>
                    </div>
                    <div class="meta__item">
                        <span class="label">Điểm CLPV</span>
                        <span class="content">{{data.serviceQualityPoint ? data.serviceQualityPoint : 0}}</span>
                    </div>
                </div>
            </div>

            <div class="data">
                <div class="overlay" />
                <div class="data-container">
                    <div class="data-container__header">
                <span class="data__title">
                    Rating
                </span>
                        <div class="data__action"></div>
                    </div>
                    <div class="data-container__content">
                        <div v-if="!loading" class="rating-table">
                            <v2-table :border="true" :data="data.rating ? [data.rating]: []">
                                <v2-table-column width="15%" label="1 sao" prop="point1"></v2-table-column>
                                <v2-table-column width="15%" label="2 sao" prop="point2"></v2-table-column>
                                <v2-table-column width="15%" label="3 sao" prop="point3"></v2-table-column>
                                <v2-table-column width="15%" label="4 sao" prop="point4"></v2-table-column>
                                <v2-table-column width="15%" label="5 sao" prop="point5"></v2-table-column>
                                <v2-table-column width="15%" label="T.Bình" prop="pointAverage"></v2-table-column>
                            </v2-table>
                        </div>
                        <div v-else style="padding: 5px;">
                            <PuSkeleton :count="3" />
                        </div>
                    </div>
                    <div class="data-container__footer">

                    </div>
                </div>
                <div class="data-container">
                    <div class="data-container__header">
                <span class="data__title">
                    Feedback
                </span>
                        <div class="data__action"></div>
                    </div>
                    <div class="data-container__content">
                        <ul v-if="!loading" class="data-list">
                            <li class="data-list__header">
                                <div class="left">

                                </div>
                                <div class="right">
                                    <span>Số feedback</span>
                                </div>
                            </li>
                            <li class="list-group-title">
                                <span>Tích cực</span>
                            </li>
                            <li  v-for="(feedback) in data.feedbacks.filter(feedback => feedback.type === 1)" :key="feedback.answer" class="data-item">
                                <div class="left">{{feedback.answer}}</div>
                                <div class="right">{{feedback.count}}</div>
                            </li>
                            <li class="list-group-title">
                                <span>Cần cải thiện</span>
                            </li>
                            <li v-for="(feedback, index) in data.feedbacks.filter(feedback => feedback.type === 2)" :key="index" class="data-item">
                                <div class="left">{{feedback.answer}}</div>
                                <div class="right">{{feedback.count}}</div>
                            </li>
                        </ul>
                        <div v-else style="padding: 5px;">
                            <PuSkeleton :count="3" />
                        </div>
                    </div>
                </div>
                <div class="data-container">
                    <div class="data-container__header">
                <span class="data__title">
                    Lỗi chất lượng
                </span>
                        <div class="data__action"></div>
                    </div>
                    <div v-if="!loading" class="data-container__content">
                        <ul class="data-list">
                            <li class="data-list__header">
                                <div class="left">

                                </div>
                                <div class="right">
                                    <span>Số lỗi</span>
                                </div>
                            </li>
                            <li v-for="(error, index) in data.qualityErrors" :key="index" class="data-item">
                                <div class="left">{{error.name}}</div>
                                <div class="right">{{error.count}}</div>
                            </li>
                        </ul>
                    </div>
                    <div v-else style="padding: 5px;">
                        <PuSkeleton :count="3" />
                    </div>
                </div>
                <div class="data-container">
                    <div class="data-container__header">
                <span class="data__title">
                    Xếp hạng
                </span>
                        <div class="data__action"></div>
                    </div>
                    <div class="data-container__content">
                        <div v-if="!loading" class="ranking-table">
                            <v2-table :border="true" :data="data.qualityRanks">
                                <v2-table-column width="10%" label="Rank" prop="rank"></v2-table-column>
                                <v2-table-column width="25%" label="Nhân viên" prop="staffName"></v2-table-column>
                                <v2-table-column width="15%" label="Rating (%)" prop="ratingPercent"></v2-table-column>
                                <v2-table-column width="25%" label="Tỉ lệ tích cực (%)" prop="ratingGoodPercent"></v2-table-column>
                                <v2-table-column width="15%" label="Tỉ lệ CLPV (%)" prop="serviceQualityPercent"></v2-table-column>
                                <v2-table-column width="15%" label="Điển CLPV (%)" prop="serviceQualityPoint"></v2-table-column>
                            </v2-table>
                        </div>
                        <div v-else style="padding: 5px;">
                            <PuSkeleton :count="3" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else style="text-align: center">
            <p>Không có dữ liệu!</p>
        </div>
    </div>
</template>

<script>
    import { getReportData } from '../repository/reportRepository'
    import moment from 'moment';
    import { getCookie } from '@/utils/cookie';

    export default {
        name: "StylistReport",
        data() {
            return {
                data: {
                    feedbacks: [],
                    qualityRanks: [],
                    qualityErrors: []
                },
                menu: "YESTERDAY",
                options: {

                },
                loading: false,
                getDataStatus: true,
                staffId: this.$route.query.staffId,
                token: this.$route.query.accessToken || getCookie('AccessToken')
            }
        },
        computed: {
            getToken() {
                return this.staffId;
            }
        },
        methods: {
            async fetchData({fromDate, toDate}) {
                if (this.token && this.staffId) {
                    try {
                        this.getDataStatus = true;
                        this.loading = true;
                        let response = await getReportData({
                            fromDate, toDate,
                            token: this.token,
                            staffId: this.staffId
                        });
                        this.data = response.data;
                    } catch (e) {
                        this.getDataStatus = false;
                    } finally {
                        this.loading = false;
                    }
                } else {
                    this.loading = false
                }
            },
            sleep(milliseconds) {
                let currentTime = new Date().getTime();

                while (currentTime + milliseconds >= new Date().getTime()) {
                    window.console.log(currentTime);
                }
            },
            async onclickFilter(e) {
                switch (e) {
                    case "YESTERDAY":
                        await this.fetchData({
                            fromDate: moment().subtract(1, 'days').format("DD/MM/YYYY").toString(),
                            toDate: moment().subtract(1, 'days').format("DD/MM/YYYY").toString(),
                        });
                        this.menu = e;
                        break;
                    case "LASTMONTH":
                        await this.fetchData({
                            fromDate: moment().subtract(1, 'months').startOf('month').format("DD/MM/YYYY").toString(),
                            toDate: moment().subtract(1, 'months').endOf('month').format("DD/MM/YYYY").toString(),
                        });
                        this.menu = e;
                        break;
                    case "THISMONTH":
                        await this.fetchData({
                            fromDate: moment().startOf('month').format("DD/MM/YYYY").toString(),
                            toDate: moment().endOf('month').format("DD/MM/YYYY").toString(),
                        });
                        this.menu = e;
                        break;
                }
            }
        },
        async created() {
            await this.fetchData({
                fromDate: moment().subtract(1, 'days').format("DD/MM/YYYY").toString(),
                toDate: moment().subtract(1, 'days').format("DD/MM/YYYY").toString(),
            });
        }
    }
</script>

<style scoped>
    .container {
        background-color: #e8e8e8;
        display: flex;
        flex-direction: column;
        height: 100%;
        color: #fff;
    }
    .button-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 20px;
    }
    .button-item {
        background-color: #2b273f;
        color: #9797aa;
        font-weight: bold;
        padding: 10px;
        border: 1px solid #353148;
        font-size: 14px;
        margin: 0;
        text-decoration: none;
    }
    .button-item:first-child {
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
    }
    .button-item:last-child {
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
    }
    .button-item.active {
        background-color: #353148;
        color: #e8e8e8;
    }
    .staff-info {
        background-color: #2b273f;
        padding: 15px;
    }
    .staff-info__name {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 0px;
        margin-left: 5px;
    }
    .staff-info__name--small {
        font-size: 14px;
        font-weight: bold;
        color: #dd6179;
        margin-top: 5px;
        margin-left: 5px;
    }
    .staff-info__meta {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .meta__item {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin: 5px;
        background-color: #353148;
        padding: 15px;
        border-radius: 10px;
    }
    .label {
        color: #9797aa;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .content {
        text-align: left;
        font-size: 18px;
        font-weight: bold;
    }
    .data {
        position: relative;
    }
    .overlay {
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 40px;
        background-color: #2b273f;
    }
    .data-container {
        background-color: #ffffff;
        min-height: 50px;
        border-radius: 10px;
        margin: 15px 5px 5px;
        position: relative;
        z-index: 99;
    }
    .data-container__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .data__title {
        text-transform: uppercase;
        color: #2b273f;
        font-size: 16px;
        font-weight: bold;
        padding: 10px 10px 0;
    }
    .data-list {
        color: #2b273f;
        list-style-type: none;
        padding: 0 5px 5px;
        margin-top: 0;
    }
    .data-list__header {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
    .data-list__header .right span {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        color: #9797aa;
    }
    .data-item {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        border-top: 1px solid #e8e8e8;
        padding: 10px 0;
    }
    .data-item .left {
        font-size: 14px;
    }
    .data-item.bold {
        font-weight: bold;
        color: #353148;
    }
    .data-item .right {
        width: 20%;
        text-align: right;
    }
    .list-group-title {
        background-color: #e8e8e8;
        padding: 8px 10px;
        color: #9797aa;
        margin-top: 10px;
        font-weight: bold;
    }
    .rating-table {
        color: #000;
        padding: 5px;
        margin-top: 10px;
    }

    .rating-table .v2-table__cell {
        color: #2b273f;
        font-size: 14px;
    }

    .ranking-table {
        margin-top: 10px;
        padding: 5px;
    }

    .ranking-table .v2-table__cell {
        color: #2b273f;
        font-size: 14px;
    }
    .ranking-table .v2-table__cell .cell {
        word-break: normal;
    }
    .ranking-table .v2-table__cell.v2-table__column-cell {
        color: #2b273f;
        font-size: 12px;
        white-space: normal;
        font-weight: bold;
    }
    .rating-table .v2-table__cell.v2-table__column-cell {
        color: #353148;
        font-weight: bold;
    }
</style>