import Vue from 'vue';
import router from './routes';
import App from './App.vue';
import 'v2-table/dist/index.css';
import V2Table from 'v2-table';
import store from './store'
import Skeleton from 'vue-loading-skeleton';
import './App.css';
import { Button, Radio, Select, Checkbox, Progress, Spin, Input, Affix } from 'ant-design-vue';

import './core/components_use';
import UUID from "vue-uuid";

Vue.use(Skeleton);

Vue.use(V2Table);
Vue.use(Button);
Vue.use(Radio);
Vue.use(Select);
Vue.use(Checkbox);
Vue.use(Progress);
Vue.use(Spin);
Vue.use(Input);
Vue.use(Affix);
Vue.use(UUID);

Vue.config.productionTip = false;

new Vue({
    render: (h) => h(App),
    router,
    store,
}).$mount('#app');
