<template>
  <a-spin :spinning="isLoading">
    <div v-if="!showPageError" class="wrapper">
      <div class="title-wrapper">
        <div class="title-content">Tất cả nhiệm vụ</div>
      </div>
      <a-list item-layout="horizontal" :split="false" :data-source="data">
        <a-list-item
          slot="renderItem"
          slot-scope="item, index"
          :key="{ index }"
        >
          <a-card
            @click="showModal(item)"
            :class="
              item.status == 0
                ? 'mission-false'
                : item.status == 1
                ? 'missing'
                : item.status == 2
                ? 'mission-success'
                : item.status == 3
                ? 'mission-new'
                : ''
            "
          >
            <a-card-meta :title="item.name">
              <a-avatar slot="avatar" :src="item.icon" shape="square" />
              <a-space slot="description">
                <div class="content-desc">{{ item.type }}</div>
                <div v-if="item.status != 3">
                  <div class="content-progress">
                    Tiến độ: {{ item.progress }}
                  </div>
                  <div class="content-point">
                    {{ item.point }}
                    <a-icon
                      type="star"
                      theme="filled"
                      :style="{ color: '#FFCC33' }"
                    />
                  </div>
                </div>
                <div class="accept-mission-wrapper" v-if="item.status == 3">
                  <a-button>Ấn nhận nhiệm vụ mới</a-button>
                </div>
              </a-space>
            </a-card-meta>
          </a-card>
        </a-list-item>
      </a-list>
      <a-modal class="modal-wrapper" v-model="visible" on-ok="handleOk">
        <template slot="footer">
          <a-button
            :class="
              detailMission.status == 1
                ? 'btn-missing'
                : detailMission.status == 3
                ? 'btn-mission-new'
                : ''
            "
            key="submit"
            type="primary"
            @click="handleOk(detailMission)"
          >
            {{
              detailMission.status == 1
                ? "Tiếp tục nhiệm vụ"
                : detailMission.status == 3
                ? "Nhận nhiệm vụ"
                : ""
            }}
          </a-button>
        </template>
        <div slot="closeIcon"></div>
        <div>
          <a-card-meta
            :title="detailMission.name"
            :description="detailMission.type"
          >
            <a-avatar slot="avatar" :src="detailMission.icon" shape="square" />
          </a-card-meta>
          <div class="content-wrapper">
            <a-space>
              <div class="item-status">
                Trạng thái:
                <div
                  :class="
                    detailMission.status == 1
                      ? 'missiing'
                      : detailMission.status == 3
                      ? 'mission-new'
                      : ''
                  "
                >
                  {{
                    detailMission.status == 1
                      ? "Đang thực hiện"
                      : detailMission.status == 3
                      ? "Đang thực hiện"
                      : ""
                  }}
                </div>
              </div>
              <div>Tiến độ: {{ detailMission.progress }}</div>
              <div>
                Điểm thưởng: {{ detailMission.point }}
                <a-icon
                  type="star"
                  theme="filled"
                  :style="{ color: '#FFCC33' }"
                />
              </div>
              <div>Hạng mục: {{ detailMission.type }}</div>
              <div>Điều kiện hoàn thành: {{ detailMission.condition }}</div>
              <div :style="{ marginTop: '10px' }">{{ detailMission.description }}</div>
            </a-space>
          </div>
        </div>
      </a-modal>

      <a-modal
        class="award-modal-wrapper"
        v-model="rewardVisible"
        on-ok="handleOk"
        :maskClosable="false"
      >
        <template slot="footer">
          <a-button
            :class="dataAward.gradeId ? 'btn-okay' : 'btn-false'"
            key="submit"
            type="primary"
            @click="handleAward"
          >
            {{ dataAward.gradeId ? "Nhận thưởng" : "Tiếp tục" }}
          </a-button>
        </template>
        <div slot="closeIcon"></div>
        <div>
          <div v-if="dataAward.gradeId == 0" class="award-content-wrapper">
            <a-space size="10" class="award-space" direction="vertical">
              <div class="description">Nhiệm vụ hoàn thành</div>
              <div class="title">Thật tuyệt vời</div>
              <div class="icon-wrapper">
                <img class="icon-thumb" :src="mySVG" />
                <div class="text-point">
                  +{{ dataAward.point }}
                  <a-icon
                    type="star"
                    theme="filled"
                    :style="{ color: '#FFCC33' }"
                  />
                </div>
              </div>
            </a-space>
          </div>
          <div v-else-if="dataAward.gradeId > 0" class="award-content-wrapper">
            <a-space size="10" class="award-space" direction="vertical">
              <div class="description">Danh hiệu mới</div>
              <div class="title">{{ dataAward.gradeName }}</div>
              <div class="icon-wrapper">
                <img class="icon-thumb" :src="mySVG" />
                <img class="icon" :src="dataAward.icon" />
              </div>
              <div class="text-point">
                +{{ dataAward.point }}
                <a-icon
                  type="star"
                  theme="filled"
                  :style="{ color: '#FFCC33' }"
                />
              </div>
            </a-space>
          </div>
          <div v-else-if="isDisableMission" class="award-content-wrapper">
            <a-space size="10" class="award-space" direction="vertical">
              <div class="description">Nhiệm vụ chưa hoàn thành</div>
              <div class="title">Cố gắng lần tới nha</div>
              <div class="icon-wrapper">
                <img class="icon-thumb" :src="mySVG" />
                <img class="icon" :src="boxSVG" />
              </div>
            </a-space>
          </div>
        </div>
      </a-modal>
    </div>
    <Page401 v-else-if="pageErrorCode === 401" />
  </a-spin>
</template>

<script>
import { mapActions } from "vuex";
import {
  fetchStaffMission,
  acceptMission,
  disableMission,
  awardMission,
} from "../../repository/missionRepository";
import { mapGetters } from "vuex";
import Page401 from "../Error/Page401";

export default {
  name: "mission",
  components: { Page401 },
  data() {
    return {
      data: [],
      visible: false,
      detailStaff: {},
      isLoading: false,
      detailMission: {},
      rewardVisible: false,
      dataAward: {},
      mySVG: require("./Group 58.png"),
      boxSVG: require("./box 1.png"),
      isDisableMission: false,
      showPageError: false,
      pageErrorCode: 0
    };
  },

  computed: {
    ...mapGetters("authenticate", ["getUser"]),
  },

  created() {
    this.fetchStaffMissionn();
  },

  methods: {
    ...mapActions("authenticate", ["setUser"]),
    handleApiFetchError(error) {
      if (error && error.response && error.response.status == 401) {
        this.showPageError = true;
        this.pageErrorCode = 401;
      }
    },
    async fetchStaffMissionn() {
      this.isLoading = true;
      try {
        const { accessToken = "", token = "" } = this.$route.query;
        const params = {
          accessToken: accessToken || token,
        };
        const response = await fetchStaffMission(params);
        const { data } = response;
        this.data = data;
      } catch (error) {
        this.isLoading = false;
        this.handleApiFetchError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async acceptMission(params) {
      this.isLoading = true;
      try {
        const response = await acceptMission(params);
        const { data } = response;
        if (data) {
          this.fetchStaffMissionn();
          this.$message.success("Nhận nhiệm vụ thành công");
          this.visible = false;
        }
      } catch (error) {
        this.$message.error(
          "Đã xảy ra lỗi trong quá trình nhận nhiệm vụ vui lòng thử lại"
        );
        this.isLoading = false;
        this.visible = false;
        this.handleApiFetchError(error);
      } finally {
        this.visible = false;
        this.isLoading = false;
      }
    },
    async disableMission(params) {
      this.isLoading = true;
      try {
        const response = await disableMission(params);
        const { data } = response;

        if (data) {
          this.isDisableMission = true;
          this.rewardVisible = true;
        }
      } catch (error) {
        this.$message.error(
          "Đã xảy ra lỗi trong quá trình bỏ nhiệm vụ vui lòng thử lại"
        );
        this.isLoading = false;
        this.visible = false;
        this.handleApiFetchError(error);
      } finally {
        this.visible = false;
        this.isLoading = false;
      }
    },
    async awardMission(params) {
      this.isLoading = true;
      try {
        const response = await awardMission(params);
        const { data } = response;
        if (data) {
          this.rewardVisible = true;
          this.dataAward = data;
        }
      } catch (error) {
        this.$message.error(
          "Đã xảy ra lỗi trong quá trình nhận thưởng vui lòng thử lại"
        );
        this.isLoading = false;
        this.visible = false;
        this.handleApiFetchError(error);
      } finally {
        this.visible = false;
        this.isLoading = false;
      }
    },
    showModal(item) {
      const { accessToken = "", token = "" } = this.$route.query;
      const params = {
        accessToken: accessToken || token,
        questId: item.id,
      };
      if (item.status == 2) {
        this.awardMission({ ...params, staffId: this.detailStaff.id });
        return;
      }
      if (item.status == 0) {
        this.disableMission(params);
        return;
      }
      this.visible = true;
      this.detailMission = item;
    },
    handleOk(item) {
      const { accessToken = "", token = "" } = this.$route.query;
      const params = {
        accessToken: accessToken || token,
        questId: item.id,
      };
      if (item.status == 3) {
        this.acceptMission(params);
        this.setUser(params.accessToken);
        return;
      }
      this.visible = false;
    },
    handleAward() {
      const { accessToken = "", token = "" } = this.$route.query;
      const accessTokenCp = accessToken || token;
      this.setUser(accessTokenCp);
      this.rewardVisible = false;
      this.fetchStaffMissionn();
      this.dataAward = {};
      this.isDisableMission = false;
    },
    handleCancel() {
      this.fetchStaffMissionn();
      const { accessToken = "", token = "" } = this.$route.query;
      const accessTokenCp = accessToken || token;
      this.setUser(accessTokenCp);
    },
  },
};
</script>

<style lang="scss">
.wrapper {
  background-color: #f3f3f3;
  height: auto;
  min-height: 80vh;
  .title-wrapper {
    display: inline-block;
    margin: 8px 0;
    margin-left: 15px;
    .title-content {
    font-weight: 500;
    font-style: normal;
    font-family: 'Be Vietnam Pro', sans-serif;;
    line-height: 21px;
    font-size: 20px;
    color: #000;
    }
  }
  .ant-list-item {
    padding: 5px 0;
    margin: 0 15px;
  }
  .mission-false {
    background: #ed9f96;
    border: none;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    padding: 5px 0px;
    font-family: 'Be Vietnam Pro', sans-serif;;
  }
  .missing {
    border: none;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    padding: 5px 0px;
    font-family: 'Be Vietnam Pro', sans-serif;;
  }
  .mission-new {
    background: #ffcd5e;
    border: none;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    padding: 5px 0px;
    font-family: 'Be Vietnam Pro', sans-serif;;
  }
  .mission-success {
    background: #a7feba;
    border: none;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    padding: 5px 0px;
    font-family: 'Be Vietnam Pro', sans-serif;;
  }
  .ant-card {
    width: 100%;
    border-radius: 10px;
    .ant-card-body {
      padding: 5px;
      width: 100%;
      height: 100%;
      .ant-card-meta {
        display: flex;
        align-items: center;
        .ant-card-meta-detai {
          display: flex;
          flex-direction: column;
          justify-content: start;
          width: 100%;
          margin-bottom: 0;
          .ant-card-meta-title {
            font-size: 14px;
          }
        }
        .ant-card-meta-description {
          .content-desc {
            font-weight: 400;
            font-size: 12px;
            font-style: italic;
            line-height: 15px;
            margin-bottom: 5px;
          }
          .content-progress {
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            margin-bottom: 5px;
          }
          .content-point {
            font-weight: 600;
            line-height: 15px;
            font-size: 12px;
            margin-bottom: 5px;
          }
        }
      }
      .ant-card-meta-avatar {
        margin-right: 16px;
        padding: 15px;
        border: 1px solid #ffe38d;
        border-radius: 15px;
        .ant-avatar {
          width: 40px;
          height: 40px;
        }
      }
      .ant-card-meta-detail {
        width: 100%;
        div {
          margin-bottom: 0;
        }
      }
      .accept-mission-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        button {
          font-size: 12px;
          font-style: italic;
        }
        .ant-btn:focus,.ant-btn:active {
          color: rgba(0,0,0,.65);
          border-color: #d9d9d9;
        }
      }
    }
  }
}
.modal-wrapper {
  .ant-modal-body {
    padding: 15px;
    .ant-card-meta {
      .ant-card-meta-detail {
        div {
          margin-bottom: 0;
        }
        .ant-card-meta-title {
          overflow: inherit;
          white-space: inherit;
        }
      }
    }
    .ant-card-meta-avatar {
      margin-right: 16px;
      padding: 15px;
      border: 1px solid #ffcc33;
      border-radius: 15px;
      .ant-avatar {
        width: 60px;
        height: 100%;
      }
    }
    .content-wrapper {
      margin-top: 30px;
      .mission-new {
        color: #ffcd5e;
        margin-left: 5px;
      }
      .missiing {
        color: #a7feba;
        margin-left: 5px;
      }
      .item-status {
        display: flex;
      }
    }
  }
  .ant-modal-footer {
    border-top: none;
  }
  .btn-missing {
    width: 100%;
    background: linear-gradient(266deg, #adfa9a 0%, #89e6b7 43.5%);
    color: #fff;
    border: none;
    padding: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
  .btn-mission-new {
    width: 100%;
    background: linear-gradient(266deg, #ffc32c 0%, #ffc32c 43.5%);
    color: #fff;
    border: none;
    padding: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
  .btn-okay {
    width: 100%;
    background: linear-gradient(90.28deg, #ffcb46 3.15%, #f8fb61 99.71%);
    color: #9e852e;
    border: none;
    padding: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
}

.award-modal-wrapper {
  .ant-modal-content {
    .ant-modal-footer {
      border-top: none;
    }
  }
  .award-content-wrapper {
    .award-space {
      text-align: center;
      width: 100%;
      .description {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
      }
      .title {
        font-weight: 700;
        font-size: 22px;
        line-height: 25px;
        margin-bottom: 20px;
      }
      .icon-wrapper {
        position: relative;
        margin-bottom: 30px;
        .icon-thumb {
          width: 100%;
          padding-left: 30px;
          height: 100%;
          max-width: 260px;
          max-height: 260px;
        }
        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-weight: bold;
          padding-left: 7px;
          width: 100%;
          max-width: 110px;
          max-height: 110px;
          height: 100%;
        }
        .text-point {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 8vw;
          font-weight: bold;
          padding-left: 20px;
        }
      }
      .text-point {
        font-size: 8vw;
        font-weight: bold;
      }
    }
  }
  .btn-okay {
    width: 100%;
    background: linear-gradient(90.28deg, #ffcb46 3.15%, #f8fb61 99.71%);
    color: #9e852e;
    border: none;
    padding: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
  .btn-false {
    width: 100%;
    background: #f85f4f;
    color: #fff;
    border: none;
    padding: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
}
</style>

