import axios from 'axios';
import config from '../config';


export function fetchStaffMission(params) {
    const { accessToken } = params;
    return axios.get(`${config.managerEndpoint}/gamification/quest`, {
        headers: { Authorization: `Bearer ${accessToken}` },
    });
}

export function fetchStaffInfo(accessToken) {
    return axios.get(`${config.managerEndpoint}/gamification/profile-v2`, {
        headers: { Authorization: `Bearer ${accessToken}` },
    });
}

export function acceptMission(params) {
    const { accessToken, ...ortherParams } = params;
    return axios.put(`${config.managerEndpoint}/gamification/quest-accepted`,
        ortherParams,
        { headers: { Authorization: `Bearer ${accessToken}` } },
    );
}

export function disableMission(params) {
    const { accessToken, ...ortherParams } = params;
    return axios.put(`${config.managerEndpoint}/gamification/quest-disable`,
        ortherParams,
        { headers: { Authorization: `Bearer ${accessToken}` } },
    );
}

export function awardMission(params) {
    const { accessToken, ...ortherParams } = params;
    return axios.get(`${config.managerEndpoint}/gamification/award`, {
        params: ortherParams,
        headers: { Authorization: `Bearer ${accessToken}` },
    });
}
