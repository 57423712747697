<template>
  <a-spin :spinning="isLoading || isFirstLoading">
    <section class="vue-winwheel" v-if="!showPageError">
      <!-- <div class="staff-info">
                <div>
                    <a-avatar
                        v-if="!staffInfo.avatar"
                        size="large"
                        icon="user"
                    />
                    <span v-else class="avatar">
                        <img :src="staffInfo.avatar" alt="" />
                    </span>
                </div>
                <div class="information" v-if="staffInfo.fullname">
                    <div class="name">{{ staffInfo.fullname }}</div>
                    <div class="point">
                        {{ staffInfo.point }}
                        <img src="../../assets/star-black.png" alt="" />
                    </div>
                </div>
                <div class="btn-exchange">
                    <button @click="showModalTurn" class="btn-turn">
                        Đổi lượt
                    </button>
                </div>
            </div> -->
      <template v-if="!isMaintenance">
        <div class="mobile-container">
          <!-- <div class="btn-exchange">
            <button @click="showModalTurn" class="btn-turn">Đổi lượt</button>
          </div> -->
          <div class="flex header">
            <div style="width: 100%">
              <div class="flex center-vertical title">
                <img src="../../assets/three-leaf-clover 1.png" alt="" />
                Điểm may mắn
              </div>
              <a-progress
                :percent="(staffInfo.lostTimeCount / maxProgress) * 100"
                :show-info="false"
              />
            </div>
            <div style="margin: 0 3px 3px 15px;">
              <button @click="showModalTurn" class="btn-turn">Đổi lượt</button>
            </div>
          </div>
          <div class="wheel-wrapper">
            <div class="canvas-wrapper">
              <canvas id="canvas" width="310" height="310">
                <p
                  style="
                     {
                      color: white;
                    }
                  "
                  align="center"
                >
                  Sorry, your browser doesn't support canvas. Please try Google
                  Chrome.
                </p>
              </canvas>
              <div class="center-cycle">
                <div class="content">
                  <img src="../../assets/tam.png" alt="" />
                  <div class="num-turn">{{ staffInfo.wheelTime }} lượt</div>
                </div>
              </div>
            </div>

            <!-- <div
                        class="note-turn"
                        v-if="staffInfo && staffInfo.wheelTime == 0"
                    >
                        Bạn chưa có lượt quay nào, hãy đổi điểm để lấy lượt quay
                        trước nhé !
                    </div> -->

            <div class="button-wrapper">
              <a
                :class="`btn-play ${
                  wheelSpinning || staffInfo.wheelTime == 0 ? 'btn-disable' : ''
                }`"
                href="#"
                @click.prevent="startSpin()"
              >
                Quay quà
                <a-icon
                  v-if="loadingCallSpin"
                  class="icon-loading"
                  type="sync"
                  spin
                />
              </a>
              <div
                v-if="staffInfo.wheelTime == 0 && !wheelSpinning"
                class="note-turn"
              >
                Hết lượt rồi bạn hãy đổi thêm lượt hoặc làm nhiệm vụ để nhận
                điểm nhé
              </div>
              <div v-else class="note-turn">
                Tỷ lệ may mắn tăng cao, Tích sao quay ngay nào
              </div>
            </div>
          </div>
        </div>
        <div class="history">
          <div class="tabs">
            <div
              :class="`tab-item ${selectTab == tab.id ? 'tab-active' : ''} ${
                  wheelSpinning ? 'btn-disable' : ''
                }`"
              v-for="tab in listTab"
              :key="tab.id"
              @click="handleChangeTab(tab.id)"
            >
              {{ tab.text }}
            </div>
          </div>
          <div v-if="selectTab == 1" class="content-tab">
            <div v-for="item in listGift" :key="item.id" class="gift">
              <template v-if="item.id !== 0">
                <div class="image-gift">
                  <img :src="item.icon" alt="" />
                </div>
                <div class="content">
                  <p class="title">{{ item.name }}</p>
                  <p class="description">
                    {{ item.description }}
                  </p>
                </div>
              </template>
            </div>
          </div>
          <div v-if="selectTab == 2" class="content-tab">
            <a-skeleton active v-if="loadingTab" />
            <div
              v-else
              class="history-system"
              v-for="item in listPrizeSystem"
              :key="item.id"
            >
              <div class="staff">{{ item.staffName }}</div>
              <div class="date">
                {{ formatDate(item.createdDate) }}
              </div>
              <div class="gift-name">{{ item.giftName }}</div>
            </div>
          </div>

          <div v-if="selectTab == 3" class="content-tab">
            <a-skeleton active v-if="loadingTab" />
            <div class="none-gift" v-else-if="listPrizeStaff.length === 0" >Chưa có dữ liệu!</div>
            <div
              v-else
              v-for="item in listPrizeStaff"
              :key="item.id"
              class="gift"
            >
              <template v-if="item.id !== 0">
                <div class="image-gift">
                  <img :src="item.giftIcon" alt="" />
                </div>
                <div class="content">
                  <p class="title">{{ item.giftName }}</p>
                  <p class="description">
                    {{ item.giftDescription }}
                  </p>
                </div>
              </template>
            </div>
            
          </div>
        </div>
        <a-modal
          title=""
          :footer="null"
          :closable="false"
          :visible="modalPrize"
          wrapClassName="custom-modal-wheel"
        >
          <div class="content-modal">
            <p class="prize-name">
              {{
                `${
                  prize.id == 0 ? "Chúc bạn may mắn lần sau!" : prize.name || ""
                }`
              }}
            </p>
            <div class="prize-image">
              <img :src="prize.image" alt="" />
            </div>
            <button class="btn-receive" @click="hidePrize">
              {{ prize.id == 0 ? "Tiếp tục" : "Nhận" }}
            </button>
          </div>
        </a-modal>
        <a-modal
          title="Đổi lượt quay"
          :footer="null"
          :visible="modalTurn"
          @cancel="hideModalTurn"
          wrapClassName="custom-modal-turn"
        >
          <img slot="closeIcon" src="../../assets/icon_close.png" alt="" />
          <div class="content-modal">
            <a-row :gutter="20" style="width: 100%">
              <a-col
                :span="8"
                v-for="(item, index) in configExchange"
                :key="item.id"
              >
                <div :class="`item item-${index + 1}`">
                  <div class="content" @click="handleClickPackage(item.id)">
                    <div class="star">
                      <span>{{ item.pointExchange }}</span>
                      <img src="../../assets/star.png" alt="" />
                    </div>
                    <div class="num-turn">{{ item.timeExchange }} lượt</div>
                  </div>
                  <div
                    class="btn-check flex center-horizontal"
                    @click="handleClickPackage(item.id)"
                  >
                    <a-radio :checked="selectedPackageExchange === item.id" />
                  </div>
                </div>
              </a-col>
            </a-row>
            <span class="btn-confirm" @click="handleSubmitExchange">
              Xác nhận
            </span>
          </div>
        </a-modal>
      </template>
      <div v-else class="maintenance flex center-vertical center-horizontal">
        <div class="icon flex center-horizontal">
          <img src="../../assets/winking-face.png" alt="" />
        </div>
        <div class="content">
          <p class="title">Oops!</p>
          <p class="sub-title">
            Hệ thống đang nâng cấp để bạn có trải nghiệm tốt hơn, chút nữa quay
            lại nha!!
          </p>
        </div>
      </div>
    </section>
    <Page401 v-else-if="pageErrorCode === 401"/>
  </a-spin>
</template>

<script>
import { mapActions } from "vuex";
import * as Winwheel from "./Winwheel";
import moment from "moment";
import {
  fetchGifts,
  fetchConfigExchange,
  exchangeTurn,
  wheelProcess,
  fetchStaffInfo,
  fetchListGiftStaff,
  fetchListGift,
} from "../../repository/wheelLuckyRepository";
import Page401 from "../Error/Page401";

export default {
  name: "VueWinWheel",
  components: { Page401 },
  data() {
    return {
      loadingPrize: false,
      theWheel: null,
      modalPrize: false,
      modalTurn: false,
      wheelSpinning: false,
      WinWheelOptions: {
        textFontSize: 14,
        outterRadius: 150,
        drawMode: "image",
        lineWidth: 8,
        animation: {
          type: "spinOngoing",
          duration: 0.5,
        },
      },
      listGift: [],
      //
      staffInfo: {},
      configExchange: [],
      staffInfoAfterSpin: {},
      listTab: [
        { id: 1, text: "Danh sách phần thưởng" },
        { id: 2, text: "Lịch sử quay quà" },
        { id: 3, text: "Phần thưởng của bạn" },
      ],
      listPrizeSystem: [],
      listPrizeStaff: [],

      selectTab: 1,
      prize: {},
      selectedPackageExchange: 0,
      isLoading: false,
      isFirstLoading: false,
      loadingCallSpin: false,
      loadingTab: false,
      giftRecive: null,
      showPageError: false,
      pageErrorCode: 0,
      maxProgress: 0,
      bgWheel: "",
      isMaintenance: false,
    };
  },

  created() {
    // logic
    // this.fetchStaffInfo();
    // this.fetchListGift();
    this.isFirstLoading = true;
    Promise.all([this.fetchStaffInfo(), this.fetchListGift()]).then(() => {
      this.initSpin();
      this.isFirstLoading = false;
    });
    this.fetchConfigExchange();
  },

  methods: {
    ...mapActions("authenticate", ["setUser"]),
    sortGift(listGift) {
      return listGift.sort((a, b) => {
        return a.position - b.position;
      });
    },

    showPrize() {
      this.modalPrize = true;
    },

    hidePrize() {
      this.modalPrize = false;
      this.staffInfo = this.staffInfoAfterSpin;
      this.staffInfoAfterSpin = {};

      if (this.giftRecive && this.giftRecive.id !== 0) {
        this.listPrizeSystem = [this.giftRecive, ...this.listPrizeSystem];
        this.listPrizeStaff = [this.giftRecive, ...this.listPrizeStaff];
        this.giftRecive = null;
      }
      const { accessToken = "", token = "" } = this.$route.query;
      const accessTokenCp = accessToken || token;
      this.setUser(accessTokenCp);
      this.prize = {};
      this.resetWheel();
    },

    showModalTurn() {
      this.modalTurn = true;
    },

    hideModalTurn() {
      this.modalTurn = false;
    },

    formatDate(date) {
      return moment(date).format("HH:mm DD/MM");
    },

    handleChangeTab(tabId) {
      this.selectTab = tabId;
      switch (tabId) {
        case 2: {
          this.fetchPrizeSystem();
          break;
        }
        case 3: {
          this.fetchPrizeStaff();
          break;
        }
        default: {
          break;
        }
      }
    },

    async startSpin() {
      window.scrollTo({ top: 0, behavior: "smooth" });
      if (this.wheelSpinning === false) {
        // this.theWheel.startAnimation();
        this.wheelSpinning = true;

        // this.theWheel = new Winwheel.Winwheel({
        //     ...this.WinWheelOptions,
        //     numSegments: this.listGift.length,
        //     segments: this.listGift,
        //     animation: {
        //         type: 'spinToStop',
        //         duration: 8,
        //         spins: 8,
        //         callbackFinished: this.onFinishSpin,
        //     },
        // });

        // let loadedImg = new Image();

        // loadedImg.onload = () => {
        //     this.theWheel.wheelImage = loadedImg; // Make wheelImage equal the loaded image object.
        //     this.theWheel.draw(); // Also call draw function to render the wheel.
        // };

        // // Set the image source, once complete this will trigger the onLoad callback (above).
        // loadedImg.src = bgWheel;

        // example input prize number get from Backend
        // Important thing is to set the stopAngle of the animation before stating the spin.

        try {
          this.loadingCallSpin = true;
          const { accessToken = "", token = "" } = this.$route.query;
          const accessTokenCp = accessToken || token;
          const res = await wheelProcess(accessTokenCp);
          this.loadingCallSpin = false;
          const { data, meta } = res.data;
          if (meta && meta.success) {
            //id 0 không trúng
            const idGift = (data && data.giftId) || 0;
            const gift = this.listGift.find((gift) => gift.id == idGift);

            //update list prize
            const giftRecive = {
              id: gift.id,
              staffName: this.staffInfo.fullname,
              giftName: gift.name,
              giftDescription: gift.description,
              createdDate: new Date(),
              giftIcon: gift.icon,
            };
            this.giftRecive = giftRecive;

            //
            this.prize = gift;
            const indexGift = this.listGift.findIndex(
              (gift) => gift.id === idGift
            );
            const prizeNumber = indexGift > -1 ? indexGift + 1 : 5;
            const stopAt =
              (360 / this.listGift.length) * prizeNumber -
              360 / this.listGift.length / (Math.floor(Math.random() * 4) + 2); //ramdo [2-5]
            this.theWheel.animation.stopAngle = stopAt;
            this.theWheel.startAnimation();
            this.staffInfoAfterSpin = {
              ...this.staffInfo,
              ...data.staff,
            };
            this.staffInfo.wheelTime -= 1;
          } else {
            this.$message.error(
              meta.externalMessage || "Có lỗi xảy ra, thử lại sau"
            );
            this.wheelSpinning = false;
            this.fetchStaffInfo();
          }
        } catch (error) {
          this.wheelSpinning = false;
          this.handleApiFetchError(error);
        }
      }
    },

    resetWheel() {
      this.theWheel.rotationAngle = 0; // Re-set the wheel angle to 0 degrees.
      this.theWheel.draw(); // Call draw to render changes to the wheel.
      this.wheelSpinning = false; // Reset to false to power buttons and spin can be clicked again.
    },

    initSpin() {
      this.loadingPrize = true;
      this.theWheel = new Winwheel.Winwheel({
        ...this.WinWheelOptions,
        numSegments: this.listGift.length,
        segments: this.listGift,
        animation: {
          type: "spinToStop",
          duration: 8,
          spins: 8,
          callbackFinished: this.onFinishSpin,
        },
      });

      let loadedImg = new Image();

      loadedImg.onload = () => {
        this.theWheel.wheelImage = loadedImg; // Make wheelImage equal the loaded image object.
        this.theWheel.draw(); // Also call draw function to render the wheel.
      };

      // Set the image source, once complete this will trigger the onLoad callback (above).
      loadedImg.src = this.bgWheel;
      this.loadingPrize = false;
    },

    onFinishSpin() {
      this.showPrize();
    },

    handleApiFetchError(error) {
      if (error && error.response && error.response.status == 401) {
        this.showPageError = true;
        this.pageErrorCode = 401;
      }
    },

    async fetchStaffInfo() {
      this.isLoading = true;
      try {
        const { accessToken = "", token = "" } = this.$route.query;
        const accessTokenCp = accessToken || token;
        const response = await fetchStaffInfo(accessTokenCp);
        const { data } = response;
        this.staffInfo = data.data;
        this.maxProgress = data.maxProgress || 0;
        if (this.bgWheel === "") {
          this.bgWheel = data.wheelImage || "";
        }
        this.isMaintenance = data.isMaintained;
      } catch (error) {
        this.handleApiFetchError(error);
        // this.$message.error('Lấy thông tin staff thất bại!');
      } finally {
        this.isLoading = false;
      }
    },

    async fetchConfigExchange() {
      try {
        const { accessToken = "", token = ""} = this.$route.query;
        const accessTokenCp = accessToken || token;
        const response = await fetchConfigExchange(accessTokenCp);
        const { data } = response;
        this.configExchange = data.data;
      } catch (error) {
        console.log(error);
        this.handleApiFetchError(error);

      }
    },

    async fetchListGift() {
      this.isLoading = true;
      try {
        const { accessToken = "", token ="" } = this.$route.query;
        const accessTokenCp = accessToken || token;
        const response = await fetchGifts(accessTokenCp);
        const { data } = response;
        this.listGift = this.sortGift(data.data || []);
      } catch (error) {
        this.$message.error("Có lỗi xảy ra, hãy thử lại sau");
        this.handleApiFetchError(error);
      } finally {
        this.isLoading = false;
      }
    },

    handleClickPackage(idPackage) {
      this.selectedPackageExchange = idPackage;
    },

    async handleSubmitExchange() {
      const { point = -1 } = this.staffInfo;
      const packageSelected = this.configExchange.find(
        (item) => item.id === this.selectedPackageExchange
      );
      const pointPackage =
        (packageSelected && packageSelected.pointExchange) || 0;
      if (point < pointPackage) {
        this.$message.warn("Không đủ điểm");
        return;
      }
      this.hideModalTurn();
      this.isLoading = true;
      try {
        const { accessToken = "", token = ""} = this.$route.query;
        const params = {
          accessToken: accessToken || token,
          idPackage: this.selectedPackageExchange,
        };
        const response = await exchangeTurn(params);
        const { data } = response;
        const { meta, data: staffInfo } = data;
        if (meta && meta.success) {
          this.staffInfo = { ...this.staffInfo, ...staffInfo };
          this.$message.success("Đổi lượt thành công");
          this.setUser(params.accessToken);
        } else {
          this.$message.error(meta.externalMessage || "Có lỗi xảy ra");
          this.setUser(params.accessToken);
        }
      } catch (error) {
        this.handleApiFetchError(error);
      } finally {
        this.selectedPackageExchange = 0;
        this.isLoading = false;
      }
    },

    async fetchPrizeSystem() {
      this.loadingTab = true;
      try {
        const { accessToken = "", token = "" } = this.$route.query;
        const params = {
          accessToken: accessToken || token,
          page: 0,
          size: 10,
          sort: "id,DESC",
        };
        const response = await fetchListGift(params);
        const { meta, data } = response.data;
        if (meta.success) {
          this.listPrizeSystem = data.content || [];
        }
      } catch (error) {
        this.handleApiFetchError(error);
      } finally {
        this.loadingTab = false;
      }
      this.loadingTab = false;
    },

    async fetchPrizeStaff() {
      this.loadingTab = true;
      try {
        const { accessToken = "", token = "" } = this.$route.query;
        const params = {
          accessToken: accessToken || token,
          page: 0,
          size: 10,
          sort: "id,DESC",
        };
        const response = await fetchListGiftStaff(params);
        const { meta, data } = response.data;
        if (meta.success) {
          this.listPrizeStaff = data.content || [];
        }
      } catch (error) {
        this.handleApiFetchError(error);
      } finally {
        this.loadingTab = false;
      }
      this.loadingTab = false;
    },
  },

  computed: {},
  updated() {},
  mounted() {
    // this.initSpin();
    // this.resetWheel()
  },
};
</script>

<style lang="scss">
.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100vh;
}

.header {                                                                       
  .ant-progress-success-bg,
  .ant-progress-bg {
    background: linear-gradient(180deg, #6bf43a 0%, #19a856 100%);
  }
  .ant-progress-inner {
    border: 1px solid #19a856;
  }
}

.btn-exchange {
  flex: 1;
  text-align: right;
  margin: auto;
  margin-right: 15px;
  margin-top: 70px;

  .btn-turn {
    background: #161616;
    border: none;
    border-radius: 5px;
    font-family: 'Be Vietnam Pro', sans-serif;
    font-size: 12px;
    text-align: center;
    color: #ffffff;
    width: 80px;
    padding: 3px;
    cursor: pointer;
    margin: auto;

    &:focus {
      outline: none;
    }
  }
}

.custom-modal-turn {
  .ant-modal-header {
    padding: 8px;
    border: none;

    .ant-modal-title {
      font-family: 'Be Vietnam Pro', sans-serif;
      font-weight: bold;
      font-size: 15px;
      color: #000000;
    }
  }

  .ant-modal-body {
    padding: 14px 8px;
  }

  .ant-modal-close {
    display: flex;
    padding: 4px;
    .ant-modal-close-x {
      height: auto;
      width: auto;
      line-height: unset;
    }
  }

  .content-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000000;
    font-family: 'Be Vietnam Pro', sans-serif;
    .btn-confirm {
      background: #c4c4c4;
      border-radius: 20px;
      padding: 3px 16px 5px 16px;
      font-size: 15px;
      text-align: center;
      margin-top: 18px;
    }

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;

      .content {
        border: 1px solid #84e508;
        border-radius: 10px;
        width: 100%;
        .star {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 8px;
          padding-bottom: 8px;
          font-style: italic;
          font-size: 13px;
          img {
            margin-left: 4px;
          }
        }

        .num-turn {
          width: 100%;
          background: #84e608;
          border-radius: 0px 0px 10px 10px;
          display: flex;
          justify-content: center;
          padding-top: 2px;
          padding-bottom: 2px;
          font-size: 15px;
          color: #ffffff;
        }
      }

      .btn-check {
        margin-top: 4px;

        .ant-radio-wrapper {
          margin-right: 0px;
        }

        .ant-radio-inner {
          border: 2px solid #000000;
        }

        .ant-radio-inner::after {
          top: 2px;
          left: 2px;
          background-color: #000000;
        }
      }
    }

    .item-2 {
      .content {
        border: 1px solid#FFCC33;
        .num-turn {
          background: #ffcc33;
        }
      }
    }

    .item-3 {
      .content {
        border: 1px solid#B251FF;
        .num-turn {
          background: #b251ff;
        }
      }
    }
  }
}

.custom-modal-wheel {
  .ant-modal {
    top: 130px;
    padding-bottom: 0px;
  }
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    justify-content: center;
  }

  .ant-modal-body {
    width: 310px;
    height: 310px;
    padding: 14px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #ffffff 0%,
      rgba(255, 255, 255, 0.51) 52.08%,
      rgba(254, 254, 254, 0.51) 74.47%,
      rgba(254, 254, 254, 0.49) 74.48%,
      rgba(254, 254, 254, 0) 100%
    );
  }

  .content-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;

    .prize-name {
      font-family: 'Be Vietnam Pro', sans-serif;
      font-style: italic;
      font-weight: bold;
      font-size: 20px;
      color: #000000;
      margin-bottom: 8px;
    }

    .prize-image {
      /* display: flex;
            justify-content: center; */
      img {
        width: 90px;
        height: auto;
      }
    }

    .btn-receive {
      background: #ffcc33;
      border-radius: 10px;
      width: 142px;
      font-family: 'Be Vietnam Pro', sans-serif;
      font-style: italic;
      font-weight: 600;
      font-size: 20px;
      text-align: center;
      color: #3e2a04;
      margin-top: 8px;
      cursor: pointer;
    }
  }
}
</style>

<style scoped lang="scss">
.vue-winwheel {
  text-align: center;
  background-color: #fff;

  .staff-info {
    background: #ffcc33;
    border-radius: 0px;
    font-family: 'Be Vietnam Pro', sans-serif;
    color: #333333;
    padding: 8px 10px;
    display: flex;
    flex-direction: row;

    .avatar {
      img {
        width: 40px;
        height: 40px;
        object-fit: contain;
        border-radius: 50%;
        overflow: hidden;
      }
    }

    .information {
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      text-align: left;
      padding-left: 10px;

      .name {
        font-weight: bold;
        font-size: 16px;
        text-transform: uppercase;
      }

      .point {
        font-weight: 500;
        font-size: 12px;
        display: flex;
        align-items: center;
        img {
          margin-left: 4px;
        }
      }
    }

    .btn-exchange {
      flex: 1;
      text-align: right;
      margin: auto;

      .btn-turn {
        background: #161616;
        border: none;
        border-radius: 5px;
        font-family: 'Be Vietnam Pro', sans-serif;
        font-size: 12px;
        text-align: center;
        color: #ffffff;
        width: 80px;
        padding: 3px;
        cursor: pointer;
        margin: auto;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .header {
    justify-content: space-between;
    align-items: flex-end;
    padding: 15px;
    margin-bottom: 10px;

    .title {
      font-family: 'Be Vietnam Pro', sans-serif;
      font-weight: 600;
      font-size: 14px;
      color: #000000;

      img {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
    }

    .btn-turn {
      background: #ffcc33;
      border: 1px solid #d9a407;
      border-radius: 5px;
      font-family: 'Be Vietnam Pro', sans-serif;
      font-size: 12px;
      text-align: center;
      color: #ffffff;
      width: 80px;
      padding: 3px;
      cursor: pointer;
    }
  }

  canvas#canvas {
    position: relative;
  }

  .canvas-wrapper {
    position: relative;

    .center-cycle {
      position: absolute;
      top: 47%;
      left: 50%;
      transform: translate(-50%, -50%);

      .num-turn {
        position: absolute;
        top: 35px;
        color: white;
        text-align: center;
        font-family: 'Be Vietnam Pro', sans-serif;
        font-style: italic;
        font-weight: 600;
        font-size: 13px;
      }

      img {
        width: 75px;
        height: auto;
      }
    }

    .content {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .wheel-wrapper {
    position: relative;

    .note-turn {
      background: #ffffff;
      border-radius: 154px;
    }

    .button-wrapper {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .note-turn {
        margin-top: 4px;
        font-family: 'Be Vietnam Pro', sans-serif;
        font-size: 13px;
        color: black;
        font-style: italic;
        padding: 0px 20px;
      }
    }

    .btn-play {
      width: 200px;
      padding: 5px;
      border: 4px solid #d69618;
      border-radius: 10px;
      background: linear-gradient(
        180deg,
        #b7f3a1 0%,
        #43e10b 33.33%,
        #43e10b 77.08%,
        #87df68 100%
      );
      font-style: italic;
      font-weight: 600;
      font-size: 20px;
      text-align: center;
      color: #ffffff;
      font-family: 'Be Vietnam Pro', sans-serif;
      text-transform: uppercase;
      position: relative;

      .icon-loading {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: lightgray;
      }
    }

    .btn-disable {
      cursor: not-allowed;
      opacity: 0.7;
      pointer-events: none;
    }
  }

  .history {
    margin-top: 25px;
    .tabs {
      font-family: 'Be Vietnam Pro', sans-serif;
      font-size: 13px;
      text-align: center;
      color: #ffffff;
      background: #9e9e9e;
      border-radius: 5px 5px 0px 0px;
      display: flex;
      flex-direction: row;

      .tab-item {
        padding: 4px;
        flex: 1;
      }

      .btn-disable {
        cursor: not-allowed;
        opacity: 0.7;
        pointer-events: none;
      }

      .tab-active {
        background: #161616;
        border-radius: 5px 5px 0px 0px;
      }
    }

    .content-tab {
      min-height: 200px;
      // overflow-y: auto;
    }

    .none-gift {
      font-style: italic;
      padding: 30px 0;
    }

    .gift {
      display: flex;
      flex-direction: row;
      margin: 10px 15px;

      p {
        margin-bottom: 0px;
      }

      .image-gift {
        width: 54px;
        height: 54px;
        background: #fee4ab;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 36px;
        }
      }

      .content {
        flex: 1;
        text-align: left;
        border-bottom: 1px solid #9e9e9e;
        margin-left: 15px;
        padding-bottom: 8px;

        .title {
          font-weight: 700;
          font-size: 16px;
          color: #000000;
        }

        .description {
          font-size: 13px;
          color: #000000;
        }
      }
      &:last-child {
        .content {
          border-bottom: none;
        }
      }
    }

    .history-system {
      display: flex;
      flex-direction: row;
      padding: 10px 0px;
      margin: 0px 15px;
      border-bottom: 1px solid #c7c7c7;
      color: #000000;

      .staff {
        flex: 1;
        font-weight: bold;
        font-size: 15px;
        text-align: left;
      }

      .date {
        flex: 1;
        font-size: 15px;
        text-align: right;
      }

      .gift-name {
        flex: 1;
        font-size: 15px;
        text-align: right;
      }
    }
  }

  .maintenance {
    flex-direction: column;
    min-height: calc(100vh - 58px);
    .icon {
      img {
        width: 150px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      color: #5a5a5a;
      padding: 15px;
      .title {
        font-family: Roboto;
        font-size: 60px;
      }
      .sub-title {
        font-family: 'Be Vietnam Pro', sans-serif;
        font-size: 16px;
        text-align: center;
      }
    }
  }
}

.flex {
  display: flex;
}

.center-vertical {
  align-items: center;
}

.center-horizontal {
  justify-content: center;
}
</style>
