<template>
    <section class="page_404">
        <div class="container">
            <div class="four_zero_four_bg">
                <h1 style="text-align: center">500</h1>


            </div>

            <div class="contant_box_404">
                <h2 class="h2">
                    Lỗi hệ thống!!
                </h2>
                <p style="margin-top: 0">Bạn vui lòng liên hệ team phát triển!</p>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "page403"
    }
</script>

<style scoped>
    .page_404{
        padding:40px 0; background:#fff;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-direction: column;
        min-height: calc(100vh);
        overflow: hidden;
    }

    .page_404  img{ width:100%;}

    .four_zero_four_bg{
        background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
        flex: 1;
        height: 60vh;
        min-width: 500px;
        background-position: center;
    }


    .four_zero_four_bg h1{
        font-size:80px;
    }

    .four_zero_four_bg h3{
        font-size:80px;
    }

    .link_404{
        color: #fff!important;
        padding: 10px 20px;
        background: #39ac31;
        margin: 20px 0;
        display: inline-block;}
    .contant_box_404{ margin-top:-50px; text-align: center}
</style>