var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.isLoading}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title"},[_vm._v(" Anh hãy điền để bọn em phục vụ anh tốt hơn ")]),_c('div',{staticClass:"content"},[_vm._l((_vm.dataQuestion),function(question){return _c('DropDown',{key:question.questionId,attrs:{"title":question.content},on:{"onToggle":_vm.updateAffix}},[(!question.isMultichoice)?_c('div',{staticClass:"content-question"},[_c('a-radio-group',{staticStyle:{"width":"100%"},attrs:{"value":_vm.getValueRadio(question.questionId)},on:{"change":function (event) { return _vm.handleChangeRadio(
                                    question.questionId,
                                    event
                                ); }}},_vm._l((question.answer),function(answer){return _c('div',{key:answer.id},[_c('a-radio',{staticClass:"radio-button",style:(_vm.radioStyle),attrs:{"value":JSON.stringify(answer)}},[_vm._v(" "+_vm._s(answer.type == _vm.ORTHER_TYPE ? 'Khác' : answer.content)+" "),(answer.type == _vm.ORTHER_TYPE)?_c('a-input',{ref:((question.questionId) + "_" + (answer.id)),refInFor:true,attrs:{"disabled":_vm.checkDisableInput(
                                            question.questionId,
                                            answer.id
                                        ),"value":_vm.getValueInput(
                                            question.questionId,
                                            answer.id
                                        )},on:{"change":function (event) { return _vm.handleChangeInput(
                                                question.questionId,
                                                answer.id,
                                                event
                                            ); }}}):_vm._e()],1)],1)}),0)],1):_vm._e(),(question.isMultichoice)?_c('div',{staticClass:"content-question"},_vm._l((question.answer),function(answer){return _c('a-checkbox',{key:answer.id,staticClass:"checkbox",attrs:{"checked":_vm.getValueCheckBox(question.questionId, answer.id)},on:{"change":function (event) { return _vm.handleChangeCheckBox(
                                    question.questionId,
                                    answer,
                                    event
                                ); }}},[_vm._v(" "+_vm._s(answer.type == _vm.ORTHER_TYPE ? 'Khác' : answer.content)+" "),(answer.type == _vm.ORTHER_TYPE)?_c('a-input',{ref:((question.questionId) + "_" + (answer.id)),refInFor:true,staticStyle:{"margin-left":"3px"},attrs:{"disabled":_vm.checkDisableInput(
                                    question.questionId,
                                    answer.id
                                ),"value":_vm.getValueInput(
                                    question.questionId,
                                    answer.id
                                )},on:{"change":function (event) { return _vm.handleChangeInput(
                                        question.questionId,
                                        answer.id,
                                        event
                                    ); }}}):_vm._e()],1)}),1):_vm._e()])}),_c('a-affix',{ref:"affixRef",attrs:{"offset-bottom":10}},[_c('div',{staticClass:"footer"},[_c('div',{staticClass:"button",on:{"click":_vm.handleSendAnswer}},[_vm._v(" Cập nhật ")])])])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }