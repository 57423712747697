<template>
  <a-spin :spinning="isLoading">
    <div v-if="!showPageError" class="news">
      <div class="body">
        <h1 class="body-title">30Shine News</h1>
        <div
          v-infinite-scroll="handleInfiniteOnLoad"
          class="demo-infinite-container"
          :infinite-scroll-disabled="busy"
          :infinite-scroll-distance="10"
        >
          <a-list class="list" item-layout="horizontal" :data-source="data">
            <a-list-item
              slot="renderItem"
              slot-scope="item, index"
              :key="index"
              v-on:click="onDetail(item.newsId)"
            >
              <div class="list-item">
                <img class="list-item-image" :src="item.icon" />
                <div class="list-item-body">
                  <div class="list-item-body-title">{{ item.title }}</div>
                  <div class="list-item-body-description">
                    <div class="list-item-body-description-category">
                      {{ item.subTitle }}
                    </div>
                    <div
                      :class="
                        item.state == 0
                          ? 'list-item-body-description-new'
                          : item.state == 1
                          ? 'list-item-body-description-read'
                          : item.state == 2
                          ? 'list-item-body-description-noneread'
                          : ''
                      "
                    >
                      {{
                        item.state === 0
                          ? "Tin mới"
                          : item.state === 1
                          ? "Đã xem"
                          : item.state === 2
                          ? "Chưa xem"
                          : null
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </div>
    <Page401 v-else-if="pageErrorCode === 401" />
  </a-spin>
</template>
<script>
import Page401 from "../Error/Page401";
import { getNews } from "../../repository/newsRepository";
import infiniteScroll from "vue-infinite-scroll";
export default {
  directives: { infiniteScroll },
  name: "news",
  components: { Page401 },
  data() {
    return {
      data: [],
      isLoading: false,
      showPageError: false,
      pageErrorCode: 0,
      busy: false,
      pageSize: 10,
    };
  },
  created() {
    this.fetchNews();
  },
  methods: {
    handleApiFetchError(error) {
      if (error && error.response && error.response.status == 401) {
        this.showPageError = true;
        this.pageErrorCode = 401;
      }
    },
    onDetail: function (e) {
      const { accessToken = "", token = "" } = this.$route.query;
      this.$router.push({
        path: `30shine-news/detail/${e}`,
        query: { token: accessToken || token },
      });
    },
    async fetchNews() {
      const data1 = this.data;
      this.isLoading = true;
      try {
        const { accessToken = "", token = "" } = this.$route.query;
        const params = {
          accessToken: accessToken || token,
          pageNum: 1,
          pageSize: data1.length + this.pageSize,
        };
        const response = await getNews(params);
        const { data } = response;
        this.data = data.data;
        if (data.maxPage === 1) {
          this.busy = true;
        }
      } catch (error) {
        this.isLoading = false;
        this.handleApiFetchError(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleInfiniteOnLoad() {
      if (this.busy) {
        return;
      }
      this.fetchNews();
    },
  },
};
</script>

<style lang="scss">
.news {
  max-width: 1024px;
  margin: auto;
  .body {
    max-width: 500px;
    margin: auto;
    background-color: #fff;
    padding: 10px 15px;
    &-title {
      font-family: 'Be Vietnam Pro', sans-serif;
      font-size: 24px;
      font-weight: 700;
    }
  }
}
.list {
  &-item {
    display: flex;
    width: 100%;
    &-image {
      width: 80px;
      height: 80px;
      border-radius: 20px;
      object-fit: cover;
    }
    &-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 24px;
      &-title {
        font-family: 'Be Vietnam Pro', sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: #000;
      }
      &-description {
        margin-top: 4px;
        display: flex;
        justify-content: space-between;
        &-category {
          font-family: 'Be Vietnam Pro', sans-serif;
          font-size: 14px;
          font-weight: 600;
          color: #8c8c8c;
        }
        &-new {
          font-family: 'Be Vietnam Pro', sans-serif;
          font-size: 14px;
          font-weight: 500;
          color: #ea6940;
          font-style: italic;
        }
        &-read {
          font-family: 'Be Vietnam Pro', sans-serif;
          font-size: 14px;
          font-weight: 500;
          color: #9a9a9a;
          font-style: italic;
        }
        &-noneread {
          font-family: 'Be Vietnam Pro', sans-serif;
          font-size: 14px;
          font-weight: 500;
          color: #4084ea;
          font-style: italic;
        }
      }
    }
  }
}
</style>
