import axios from 'axios';
import config from '../config';

const resources = '/api/v1/tracking-dynamic';


export function postTracking(data) {
    //const { accessToken, ...ortherParams } = params;
    return axios.post(
        `${config.trackingEndpoint}${resources}`,
        data
    );
}

