<template>
	<a-spin :spinning="isLoading">
		<div class="container-salary">
			<div v-if="isShowData && !isNotStSk">
				<div v-if="salaryDataItem == null" class="no-data-show">Không có dữ liệu.</div>
				<div v-else>
					<div class="information-staff">
						<div class="information-staff__img">
							<img
								:src="salaryDataItem.staff.linkAvatar"
								alt="Avatar"
								v-if="salaryDataItem.staff.linkAvatar"
							/>
							<img src="../../assets/icon_user.png" alt="Avatar" v-else />
						</div>
						<div class="information-staff__info">
							<div class="info__fullName">
								{{ salaryDataItem.staff.fullName }}
							</div>
							<div class="info__staff">
								<span class="info__text">
									{{ salaryDataItem.staff.departmentName }}
								</span>
								<span class="info__text">
									Salon: {{ salaryDataItem.staff.salonShortName }}
								</span>
							</div>
						</div>
						<div class="information-staff__rating">
							<b>Rating trung bình 30 ngày:</b>
							<div class="rating" v-if="salaryDataItem.staffRating">
								<span class="rating__title">
									{{ salaryDataItem.staff.departmentName }}
								</span>
								<span class="rating__number">
									{{ roundNumber(salaryDataItem.staffRating.avgRate) }}
									<a-icon type="star" theme="filled" />
									({{ salaryDataItem.staffRating.totalBill }}
									khách)
								</span>
							</div>
							<div class="rating" v-if="salaryDataItem.salonRating">
								<span class="rating__title">Salon</span>
								<span class="rating__number">
									{{ roundNumber(salaryDataItem.salonRating.avgRate) }}
									<a-icon type="star" theme="filled" />
									({{ salaryDataItem.salonRating.totalBill }}
									khách)
								</span>
							</div>
						</div>
					</div>
					<div class="tabs">
						<a-tabs :animated="false" :activeKey="activeTab" @tabClick="setActiveTab">
							<a-tab-pane key="1">
								<div slot="tab">
									<img
										src="../../assets/icon_salary.png"
										alt=""
										v-if="activeTab === '2'"
										class="tabs-img"
									/>
									<img
										src="../../assets/icon_salary_active.png"
										alt=""
										v-if="activeTab === '1'"
										class="tabs-img"
									/>
									<div class="tabs-title">Lương</div>
								</div>
								<div class="">
									<RankingCustomers v-if="isSupporter" />
									<!-- <div class="select-date">
										<div
											class="select-date_tab"
											v-bind:class="[
												selectedDate == 1 ? 'select-date_tab-active' : '',
											]"
											@click="selectedDateTime(1)"
										>
											Chọn ngày
										</div>
										<div
											class="select-date_tab"
											v-bind:class="[
												selectedDate == 5 ? 'select-date_tab-active' : '',
											]"
											@click="selectedDateTime(5)"
										>
											Hôm nay
										</div>
										<div
											class="select-date_tab"
											v-bind:class="[
												selectedDate == 2 ? 'select-date_tab-active' : '',
											]"
											@click="selectedDateTime(2)"
										>
											Hôm qua
										</div>
										<div
											class="select-date_tab"
											v-bind:class="[
												selectedDate == 3 ? 'select-date_tab-active' : '',
											]"
											@click="selectedDateTime(3)"
										>
											Tháng này
										</div>
										<div
											class="select-date_tab"
											v-bind:class="[
												selectedDate == 4 ? 'select-date_tab-active' : '',
											]"
											@click="selectedDateTime(4)"
										>
											Tháng trước
										</div>
									</div>

									<div class="date-selected-view">
										<div class="flex">
											<div class="icon-datetime">
												<img src="../../assets/icon_calendar.png" alt="" />
											</div>
											<div class="text-datetime">{{ dateTimeSelect }}</div>
										</div>
									</div> -->
									<!-- <Salary
										:isShowSalaryToday="isShowSalaryToday"
										:salaryDataItem="salaryDataItem"
										:staffId="staffId"
										:fromDate="formDate"
										:toDate="toDate"
										:isSelectDate="isSelectDate"
										:isSupporter="isSupporter"
									/> -->
									<iframe :src="iframeSrc" width="100%" style="border:0; scrolling:no; height: calc(100vh - 234px + 22px);"></iframe>
								</div>
							</a-tab-pane>
							<a-tab-pane key="2">
								<div slot="tab">
									<img
										src="../../assets/icon_experience.png"
										alt=""
										v-if="activeTab === '1'"
										class="tabs-img"
									/>
									<img
										src="../../assets/icon_experience_active.png"
										alt=""
										v-if="activeTab === '2'"
										class="tabs-img"
									/>
									<div class="tabs-title">Bậc kỹ năng</div>
								</div>
								<StaffSkillLevel />
							</a-tab-pane>
						</a-tabs>
					</div>
				</div>
			</div>
			<div v-if="isNotStSk" class="no-data-show">
				App bạn đang đăng nhập xem Thu nhập dành cho Stylist, Skinner, Checkin. Vui lòng
				đăng nhập App phù hợp với vị trí của bạn.
			</div>
			<a-modal
				v-model="modalVisible"
				title="Chọn ngày"
				@ok="selectDate"
				@cancel="cancelSelectDate"
			>
				<div>
					<a-date-picker
						format="YYYY-MM-DD"
						:disabled-date="disabledDate"
						v-model="customerDate"
					/>
				</div>
			</a-modal>
		</div>
	</a-spin>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
// import StaffSkillLevel from './StaffSkillLevel.vue';
// import RankingCustomers from './components/RankingCustomers.vue';
// import Salary from './Salary.vue';
import { getCookie } from '@/utils/cookie';

export default {
	name: 'SalaryReport',
	components: {
		// StaffSkillLevel,
		// RankingCustomers,
		// Salary,
	},
	data() {
		return {
			activeTab: '1',
			isSupporter: false,
			isSelectDate: true,
			salaryDataItem: null,
			selectedDate: 2,
			dateTimeSelect: moment().subtract(1, 'days').format('DD/MM/YYYY').toString(),
			API_STYNEXT: process.env.VUE_APP_API_STYNEXT,
			VUE_APP_API_STAFF_VIEW: process.env.VUE_APP_API_STAFF_VIEW,
			staffId: this.$route.query.staffId,
			token: this.$route.query.accessToken || getCookie('AccessToken'),
			formDate: moment().subtract(1, 'days').format('YYYY-MM-DD').toString(),
			toDate: moment().format('YYYY-MM-DD').toString(),
			isLoading: true,
			isNotStSk: false,
			isShowData: false,
			modalVisible: false,
			customerDate: null,
			isShowSalaryToday: true,
			iframeSources: [
				'https://app.powerbi.com/view?r=eyJrIjoiZGQ0ZWEyOTQtNDUwYi00MWRlLWIwOTAtNzZhMmRmOWNjOTJkIiwidCI6ImZkNjE5MTU3LWM5MGItNDUwZC1hODI5LTg1OTE2YmExNmRhMyIsImMiOjEwfQ%3D%3D&pageName=ReportSectione9162c8c7b3018b012cd',
				'https://app.powerbi.com/view?r=eyJrIjoiZGQ0ZWEyOTQtNDUwYi00MWRlLWIwOTAtNzZhMmRmOWNjOTJkIiwidCI6ImZkNjE5MTU3LWM5MGItNDUwZC1hODI5LTg1OTE2YmExNmRhMyIsImMiOjEwfQ%3D%3D&pageName=ReportSection3aaeb537d84cc0754847'
			],
			iframeSrc: '',
		};
	},
	methods: {
		moment,
		range(start, end) {
			const result = [];
			for (let i = start; i < end; i++) {
				result.push(i);
			}
			return result;
		},
		disabledDate(current) {
			var startDate = moment('2020-11-01').format('YYYY-MM-DD').toString();
			var dateNow = moment().format('YYYY-MM-DD').toString();
			return (
				current &&
				(current < moment(startDate, 'YYYY-MM-DD') ||
					current > moment(dateNow, 'YYYY-MM-DD'))
			);
		},
		selectDate() {
			this.modalVisible = false;
			this.dateTimeSelect = this.customerDate.format('DD/MM/YYYY').toString();
			this.formDate = this.customerDate.format('YYYY-MM-DD').toString();
			this.toDate = this.customerDate.subtract(-1, 'days').format('YYYY-MM-DD').toString();
			this.fetchData();
		},
		cancelSelectDate() {
			this.modalVisible = false;
			this.selectedDateTime(2);
		},
		selectedDateTime(value) {
			this.isLoading = true;
			this.modalVisible = false;
			this.isShowSalaryToday = true;
			this.isSelectDate = false;
			if (this.selectedDate != value || value == 1) {
				this.selectedDate = value;
				if (value == 1) {
					this.isSelectDate = true;
					this.modalVisible = true;
				} else if (value == 2) {
					this.isSelectDate = true;
					this.dateTimeSelect = moment()
						.subtract(1, 'days')
						.format('DD/MM/YYYY')
						.toString();
					this.formDate = moment().subtract(1, 'days').format('YYYY-MM-DD').toString();
					this.toDate = moment().format('YYYY-MM-DD').toString();
					this.fetchData();
				} else if (value == 3) {
					this.dateTimeSelect =
						moment().startOf('month').format('DD/MM/YYYY').toString() +
						' - ' +
						moment().endOf('month').format('DD/MM/YYYY').toString();
					this.formDate = moment().startOf('month').format('YYYY-MM-DD').toString();
					// this.toDate = moment()
					//   .endOf("month")
					//   .subtract(-1, "days")
					//   .format("YYYY-MM-DD")
					//   .toString();
					this.toDate = moment().format('YYYY-MM-DD').toString();
					this.fetchData();
				} else if (value == 4) {
					this.dateTimeSelect =
						moment()
							.subtract(1, 'months')
							.startOf('month')
							.format('DD/MM/YYYY')
							.toString() +
						' - ' +
						moment()
							.subtract(1, 'months')
							.endOf('month')
							.format('DD/MM/YYYY')
							.toString();
					this.formDate = moment()
						.subtract(1, 'months')
						.startOf('month')
						.format('YYYY-MM-DD')
						.toString();
					this.toDate = moment()
						.subtract(1, 'months')
						.endOf('month')
						.subtract(-1, 'days')
						.format('YYYY-MM-DD')
						.toString();
					this.fetchData();
				} else if (value == 5) {
					this.isSelectDate = true;
					this.dateTimeSelect = moment().format('DD/MM/YYYY').toString();
					this.formDate = moment().format('YYYY-MM-DD').toString();
					this.toDate = moment().subtract(-1, 'days').format('YYYY-MM-DD').toString();
					let timeNow = moment();
					let timeCompare = moment(
						moment().format('YYYY-MM-DD') + ' 23:00:00',
						'YYYY-MM-DD HH:mm:ss'
					);
					if (timeNow.unix() - timeCompare.unix() < 0) {
						this.isShowSalaryToday = false;
					}
					this.fetchData();
				}
			} else {
				this.isLoading = false;
			}
		},
		formatPrice(value, showSalary) {
			if (!showSalary) {
				return 0;
			}
			let val = (value / 1).toFixed(0).replace('.', ',');
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
		},
		async fetchData() {
			//loadding
			this.isLoading = true;
			try {
				const { data } = await axios.get(
					`${this.API_STYNEXT}/api/statistic-salary/over-view-salary?staffId=${this.staffId}&fromDate=${this.formDate}&toDate=${this.toDate}&isBySalon=false`,
					{
						headers: {
							Authorization: `Bearer ${this.token}`,
						},
					}
				);
				if (data) {
					this.salaryDataItem = data;
					const response = await axios.get(
					`${this.VUE_APP_API_STAFF_VIEW}/v1/staff/bi-salary-config?salonId=${data.staff.salonId}&departmentId=${data.staff.departmentId}`,
					{
						headers: {
							Authorization: `Bearer ${this.token}`,
						},
					}
				);
					
					this.isShowData = true;
					//lay thong tin ve vi tri cua nhan vien, neu ko phai la st, sk thi thong bao dang nhap app khac de xem luong
					// if (
					// 	this.salaryDataItem.staff != null &&
					// 	this.salaryDataItem.staff.departmentId != 1 &&
					// 	this.salaryDataItem.staff.departmentId != 2 &&
					// 	this.salaryDataItem.staff.departmentId != 5 &&
					// 	this.salaryDataItem.staff.departmentId != 3 &&
					// 	this.salaryDataItem.staff.departmentId != 6 
					// ) {
					// 	this.isNotStSk = true;
					// } else {
					// 	this.isNotStSk = false;
					// }
					// if (
					// 	this.salaryDataItem.staff != null &&
					// 	(this.salaryDataItem.staff.departmentId === 5 ||
					// 		this.salaryDataItem.staff.departmentId === 3 ||
					// 		this.salaryDataItem.staff.departmentId === 6)
					// ) {
					// 	this.isSupporter = true;
					// }

					// if (
					// 	this.salaryDataItem.staff.departmentId === 1
					// ) {
					// 	// stylist
					// 	this.iframeSrc = this.iframeSources[0]

					// } else if(
					// 	this.salaryDataItem.staff.departmentId === 2
					// ) {
					// 	// skinner
					// 	this.iframeSrc = this.iframeSources[1]
					// }
					// check nếu có data thì set link iframe
					if(!!response?.data?.data) {
						let linkBI = response?.data?.data;
						this.iframeSrc = linkBI
					}
				}
			} catch (error) {
				console.log('fetchData ReportSalary', error)
			}
			this.isLoading = false;
		},
		setActiveTab(tabKey) {
			this.activeTab = tabKey;
		},
		roundNumber(num) {
			return Math.round(num * 100) / 100;
		},
	},
	created() {
		this.fetchData();
	},
};
</script>

<style lang="scss">
.container-salary {
	font-family: 'Be Vietnam', sans-serif;
	background-color: #f3f3f3;
	min-height: 100vh;

	.no-data-show {
		text-align: center;
		position: fixed;
		top: 45%;
		width: 100%;
	}

	.ant-spin-nested-loading {
		height: 100% !important;
	}
	.border-top {
		border-top: 1px solid #d9d9d9;
	}

	.information-staff {
		display: flex;
		flex-flow: wrap;
		align-items: center;
		background: #ffdc5a;
		padding: 8px 12px 50px;
		&__img {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}
		&__info {
			padding-left: 12px;
			.info {
				&__fullName {
					color: #000000;
					font-weight: 600;
					font-size: 14px;
				}
				&__staff {
					display: flex;
				}
				&__text {
					font-size: 14px;
					color: #222222;
					padding-right: 30px;
				}
			}
		}
		&__rating {
			width: 100%;
			margin-top: 12px;
			color: #222;
			font-size: 14px;
			.rating {
				padding-top: 4px;
				display: flex;
				&__title {
					width: 70px;
				}
			}
		}
	}

	.tabs {
		margin-top: -30px;
		&-title {
			padding-top: 4px;
			color: rgba(0, 0, 0, 0.85);
			font-size: 12px;
		}
		.ant-tabs {
			&-tab {
				img {
					width: 44px;
					height: 44px;
				}
			}
			&-bar {
				padding: 0 10px;
				border: none;
				margin-bottom: 14px;
			}
			&-nav-container {
				background: #ffffff;
				box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
				border-radius: 10px;
			}
			&-nav {
				padding: 8px 0;
				width: 100%;
				> div {
					display: flex;
				}
				.ant-tabs {
					&-tab {
						flex: 1;
						margin: 0;
						padding: 0;
						&:first-child {
							&::after {
								content: '';
								width: 1px;
								position: absolute;
								right: 0;
								top: 50%;
								transform: translateY(-50%);
								height: calc(100% - 6px);
								background-color: rgba(0, 0, 0, 0.2);
							}
						}
						> div {
							display: flex;
							justify-content: center;
							flex-flow: column;
							align-items: center;
						}
					}
					&-tab-active {
						.tabs-title {
							color: #ffcc33;
						}
					}
				}
			}
			&-ink-bar {
				display: none !important;
			}
		}
	}

	.select-date {
		overflow-x: auto;
		display: flex;
		background-color: #fff;
		margin-bottom: 2px;
		margin-left: 0;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}
		&_tab {
			white-space: pre;
			cursor: pointer;
			font-size: 14px;
			color: #000000;
			border-bottom: 2px solid transparent;
			padding: 12px 8px;
			font-weight: 500;
			&-active {
				border-bottom: 2px solid #ffcc33;
			}
		}
	}

	.date-selected-view {
		padding: 12px 5px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #ffffff;
		.flex {
			display: flex;
			align-items: center;
		}
		.icon-datetime {
			img {
				width: 16px;
				height: 16px;
			}
		}
		.text-datetime {
			padding-left: 10px;
			font-size: 14px;
			color: #000000;
		}
	}
}
</style>
