<template>
  <a-spin :spinning="isLoading">
    <div v-if="!showPageError" class="detail">
      <div class="body">
        <div class="affix">
          <div class="affix-body" v-on:click="onBack">
            <a-icon class="affix-icon" type="left" />
            <div class="affix-text">Quay lại</div>
          </div>
        </div>
        <img class="thumb-image" :src="detail.banner" />
        <div class="content">
          <h1 class="content-title">
            {{ detail.title }}
          </h1>
          <p class="content-category">{{ detail.subTitle }}</p>
          <div class="content-body" v-html="detail.content">
            {{ detail.content }}
          </div>
          <div class="content-author">{{ detail.signature }}</div>
        </div>
      </div>
    </div>
    <Page401 v-else-if="pageErrorCode === 401" />
  </a-spin>
</template>
<script>
import Page401 from "../Error/Page401";
import { getNewsDetail } from "../../repository/newsRepository";
export default {
  name: "detail",
  components: { Page401 },
  data() {
    return {
      detail: {},
      isLoading: false,
      showPageError: false,
      pageErrorCode: 0,
    };
  },
  created() {
    this.fetchNews();
  },
  methods: {
    handleApiFetchError(error) {
      if (error && error.response && error.response.status == 401) {
        this.showPageError = true;
        this.pageErrorCode = 401;
      }
    },
    async fetchNews() {
      this.isLoading = true;
      try {
        const { accessToken = "", token = "" } = this.$route.query;
        const { id } = this.$route.params;
        const params = {
          accessToken: accessToken || token,
          newsId: id,
        };
        const response = await getNewsDetail(params);
        const { data } = response;
        console.log(data.data);
        this.detail = data.data;
      } catch (error) {
        this.isLoading = false;
        this.handleApiFetchError(error);
      } finally {
        this.isLoading = false;
      }
    },
    onBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
.detail {
  max-width: 1024px;
  margin: auto;
  .body {
    max-width: 500px;
    margin: auto;
    background-color: #fff;
    .affix {
      background-color: #fff;
      position: sticky;
      top: 0;
      padding: 10px;
      &-body {
        display: flex;
        align-items: center;
      }
      &-icon {
        margin-right: 10px;
        color: #000;
      }
      &-text {
        font-family: 'Be Vietnam Pro', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #000;
      }
    }
    .thumb-image {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
    .content {
      padding: 15px;
      &-title {
        font-family: 'Be Vietnam Pro', sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: #000;
        padding: 0;
        margin: 0;
      }
      &-category {
        font-family: 'Be Vietnam Pro', sans-serif;
        font-size: 14px;
        font-weight: 600;
        color: #8c8c8c;
      }
      &-body {
        overflow: auto;
      }
      &-author {
        display: flex;
        justify-content: flex-end;
        font-family: 'Be Vietnam Pro', sans-serif;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
</style>
