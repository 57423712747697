export default {
    baseEndpoint: process.env.VUE_APP_REPORT_API,
    customerInsightEndpoint: process.env.VUE_APP_CUSTOMER_INSIGHT_API,
    searchAddressEndpoint: process.env.VUE_APP_SEARCH_ADDRESS_API,
    customerEndpoint: process.env.VUE_APP_API_CUSTOMER,
    wheelLuckyEndpoint: process.env.VUE_APP_API_WHEEL_LUCKY,
    newsEndpoint: process.env.VUE_APP_API_30SHINE_NEWS,
    managerEndpoint: process.env.VUE_APP_API_MANAGER,
    trackingEndpoint: process.env.VUE_APP_API_TRACKING,
};
