import axios from 'axios';
import config from '../config';

const resources = '/api/v1';
export function fetchGifts(accessToken) {
    return axios.get(`${config.wheelLuckyEndpoint}${resources}/gifts`, {
        headers: { Authorization: `Bearer ${accessToken}` },
    });
}

export function fetchConfigExchange(accessToken) {
    return axios.get(
        `${config.wheelLuckyEndpoint}${resources}/times/exchange-configs`,
        {
            headers: { Authorization: `Bearer ${accessToken}` },
        },
    );
}

export function exchangeTurn(params) {
    const { accessToken, idPackage } = params;
    return axios.get(
        `${config.wheelLuckyEndpoint}${resources}/times/exchange/${idPackage}`,
        {
            headers: { Authorization: `Bearer ${accessToken}` },
        },
    );
}

export function wheelProcess(accessToken) {
    return axios.get(
        `${config.wheelLuckyEndpoint}${resources}/wheeling/process`,
        {
            headers: { Authorization: `Bearer ${accessToken}` },
        },
    );
}

export function fetchStaffInfo(accessToken) {
    return axios.get(`${config.wheelLuckyEndpoint}${resources}/wheeling`, {
        headers: { Authorization: `Bearer ${accessToken}` },
    });
}

export function fetchListGiftStaff(params) {
    const { accessToken, ...ortherParams } = params;
    return axios.get(
        `${config.wheelLuckyEndpoint}${resources}/wheeling/my-receive-gifts`,
        {
            headers: { Authorization: `Bearer ${accessToken}` },
            params: ortherParams,
        },
    );
}

export function fetchListGift(params) {
    const { accessToken, ...ortherParams } = params;
    return axios.get(
        `${config.wheelLuckyEndpoint}${resources}/wheeling/all-receive-gifts`,
        {
            headers: { Authorization: `Bearer ${accessToken}` },
            params: ortherParams,
        },
    );
}
