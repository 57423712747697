import { AUTHENTICATE_SET_USER } from '../../mutation-types';
import { fetchStaffInfo } from "../../../repository/missionRepository";

export default {
    async setUser({ commit }, accessToken) {
        const { data: res } = await fetchStaffInfo(accessToken);
        const user = res
        commit(AUTHENTICATE_SET_USER, { user });
    },
}
