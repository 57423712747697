<template>
    <div class="container">
        <div class="title">30Shine hiểu và phục vụ anh tốt hơn</div>
        <div class="content">
            <div class="flex center">
                <img src="../assets/checked.png" alt="" />
                <div class="title-success">Tuyệt vời</div>
            </div>
            <div class="text-thank">
                {{ textThankByOs[osCustomerInsight[os]] }}
            </div>
            <div v-if="returnUrl" class="redirect">
                <span class="redirect-message">Hệ thống sẽ tự động chuyển đến <br/> <strong>{{returnUrlName}}</strong> sau <strong>{{countDown}} giây</strong></span>
                <a :href="returnUrl" class="redirect-button">
                   Tiếp tục
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import osCustomerInsight from '../config/osCustomerInsight';

const textThankByOs = {
    web: '',
    stylist:
        'Cảm ơn Anh đã cùng chung tay để tập thể 30Shine ngày càng thấu hiểu và phục vụ khách hàng tốt hơn. Chúc Anh một ngày làm việc vui vẻ.',
    skinner:
        'Cảm ơn Chị đã cùng chung tay để tập thể 30Shine ngày càng thấu hiểu và phục vụ khách hàng tốt hơn. Chúc Chị một ngày làm việc vui vẻ.',
    customer:
        'Cảm ơn Anh vì đã chia sẻ để 30Shine hiểu và phục vụ Anh tốt hơn. Chúc Anh một ngày tốt lành nhé ạ.',
    fc:
        'Cảm ơn Bạn đã cùng chung tay để tập thể 30Shine ngày càng thấu hiểu và phục vụ khách hàng tốt hơn. Chúc Bạn một ngày làm việc vui vẻ.',
    checkout:
        'Cảm ơn Bạn đã cùng chung tay để tập thể 30Shine ngày càng thấu hiểu và phục vụ khách hàng tốt hơn. Chúc Bạn một ngày làm việc vui vẻ.',
};

export default {
    data() {
        return {
            osCustomerInsight,
            textThankByOs,
            os: 1,
            returnUrl: null,
            returnUrlName: null,
            countDown : 5
        };
    },

    created() {
        const { os = 1, returnUrl, returnUrlName } = this.$route.query;
        this.returnUrl = returnUrl;
        this.returnUrlName = returnUrlName;
        this.os = os;
        if (returnUrl) this.countDownTimer();
    },

    methods: {
        countDownTimer() {
            if(this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            } else {
                window.location = this.returnUrl;
            }
        }
    }
};
</script>

<style scoped>
* {
    font-family: 'Be Vietnam Pro', sans-serif;
    color: #000000;
}

.container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.title {
    background: #000000;
    color: white;
    font-family: 'Be Vietnam Pro', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    padding: 12px;
}

.content {
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
}

.title-success {
    font-weight: 600;
    font-size: 35px;
    line-height: 51px;
    text-align: center;
    color: #ffd200;
}

.text-thank {
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    color: #000000;
    margin-top: 30px;
}

.flex {
    display: flex;
    flex-direction: column;
}

.center {
    justify-content: center;
    align-items: center;
}

.redirect {
    margin-top: 30px;
    text-align: center;
}
.redirect-message {
    display: block;
    text-align: center;
}
.redirect-message strong {
    font-weight: bold;
    font-size: 16px;
    text-decoration: underline;
}
.redirect-button {
    margin-top: 15px;
    display: inline-block;
    background: #FFCC33;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 7px;
}
</style>
