import axios from "axios";
import config from "../config";

export function getNews(params) {
  const { accessToken, pageNum, pageSize } = params;
  return axios.get(
    `${config.newsEndpoint}/api/news?pageNum=${pageNum}&pageSize=${pageSize}`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
}
export function getNewsDetail(params) {
    const { accessToken, newsId } = params;
    return axios.get(
      `${config.newsEndpoint}/api/news/detail?newsId=${newsId}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }
