<template>
    <div class="drop-container">
        <div class="drop-content" @click="toggleShow">
            <div class="drop-title">
                {{ title }}
            </div>
            <a-icon v-if="!isShow" type="right" class="bounceAlpha" />
            <a-icon v-else type="down" />
        </div>
        <slot v-if="isShow" />
    </div>
</template>

<script>
export default {
    name: 'DropDown',

    props: {
        title: String,
    },

    data() {
        return {
            isShow: false,
        };
    },

    methods: {
        toggleShow() {
            this.isShow = !this.isShow;
            this.$emit('onToggle');
        },
    },
};
</script>

<style scoped>
.drop-container {
    padding: 5px;
    color: #424242;
}

.drop-content {
    background: #f9fafc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 10px;
}

.drop-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
}

@keyframes bounceAlpha {
    0% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }
    25% {
        opacity: 0;
        transform: translateX(5px) scale(0.9);
    }
    26% {
        opacity: 0;
        transform: translateX(-5px) scale(0.9);
    }
    55% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }
}

.bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
</style>
