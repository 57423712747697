<template>
    <div class="outer-container">
        <div v-if="getToken" class="container">
            <h2>Ảnh giới thiệu thợ trên booking</h2>

            <div v-for="containerId in [1, 2]" :key="containerId" style="display: flex; flex-direction: row; width: 100%;">
                <div v-for="i in [(containerId - 1) * 2 + 1, (containerId - 1) * 2 + 2]" :key="i" style="width: 50%; padding: 5px;">
                    <a-upload
                        name="image"
                        list-type="picture-card"
                        :show-upload-list="false"
                        :action="`${API_UPLOAD}/common`"
                        accept="image/*"
                        @change="e => handleChange(e, i)"
                    >
                        <img v-if="imageUrls[i]" :src="imageUrls[i]" alt="avatar" style="width: 100%;" />
                        <div v-else>
                            <a-icon :type="loading ? 'loading' : 'plus'" />
                            <div class="ant-upload-text">
                                Upload
                            </div>
                        </div>
                    </a-upload>
                </div>
            </div>
            

            <a-button @click="savePhotos" size="large" style="width: 200px;" :disabled="loading">Lưu</a-button>
        </div>
        <div v-else style="text-align: center">
            <p>Không có dữ liệu!</p>
        </div>

        <a-modal v-model="modalVisible" title="Basic Modal" @ok="cropImage" @cancel="onCropCancel">
            <div v-if="currentImageUrl">
                <vue-cropper
                    ref="cropper"
                    :aspect-ratio="66 / 52"
                    :src="currentImageUrl"
                    :zoomable="false"
                />
            </div>
        </a-modal>
    </div>
</template>

<script>
    // import { getReportData } from '../repository/reportRepository'
    // import moment from 'moment';
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import axios from 'axios';
    import { getCookie } from '@/utils/cookie';

    export default {
        name: "StylistReport",
        components: {
            VueCropper
        },
        data() {
            return {
                loading: false,
                staffId: this.$route.query.staffId,
                token: this.$route.query.accessToken || getCookie('AccessToken'),
                userAvatar: undefined,
                imageUrl: '',
                API_UPLOAD: process.env.VUE_APP_API_UPLOAD,
                currentImageUrl: null,
                modalVisible: false,
                currentImageId: null,
                imageUrls: []
            }
        },
        computed: {
            getToken() {
                return this.staffId;
            }
        },
        methods: {
            handleChange(info, imageId) {
                if (info.file.status === 'uploading') {
                    this.loading = true;
                    return;
                }

                if (info.file.status === 'done') {
                    this.loading = false;
                    this.currentImageUrl = info.file.response[0];
                    this.currentImageId = imageId;
                    this.modalVisible = true;
                }
            },
            cropImage() {
                const croppedImageData = this.$refs.cropper.getCroppedCanvas().toDataURL();
                console.log('croppedImageData', croppedImageData)
                this.imageUrls[this.currentImageId] = croppedImageData;
                this.modalVisible = false;
                this.currentImageUrl = null;
                this.currentImageId = null;
            },
            async savePhotos() {
                var images = this.imageUrls.filter(item => item).map(item => ({
                    imageData: item
                }))

                if (images.length < 4) {
                    this.$message.error("Vui lòng upload đủ 4 ảnh!");
                    return;
                }

                this.loading = true;

                await axios.post(`${this.API_UPLOAD}/staff/booking-favorite-photos`, {
                    images
                }, {
                    headers: {
                        "Authorization": `Bearer ${this.token}`
                    }
                })

                this.loading = false;

                this.$message.info("Upload thành công!");

                this.imageUrls = [];
            },
            onCropCancel() {
                this.currentImageUrl = null;
                this.currentImageId = null;
            }
        },
        async created() {
        }
    }
</script>

<style scoped>
    .ant-upload {
        width: 100% !important;
    }

    .container {
        background-color: #e8e8e8;
        display: flex;
        flex-direction: column;
        height: 100%;
        color: #fff;
        width: 500px;
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 200px;
       
    }

    @media only screen and (max-width: 1199px) {
        .container {
            width: 100%;
        }
    }

    .outer-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }


    .button-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 20px;
    }
    .button-item {
        background-color: #2b273f;
        color: #9797aa;
        font-weight: bold;
        padding: 10px;
        border: 1px solid #353148;
        font-size: 14px;
        margin: 0;
        text-decoration: none;
    }
    .button-item:first-child {
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
    }
    .button-item:last-child {
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
    }
    .button-item.active {
        background-color: #353148;
        color: #e8e8e8;
    }
    .staff-info {
        background-color: #2b273f;
        padding: 15px;
    }
    .staff-info__name {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 0px;
        margin-left: 5px;
    }
    .staff-info__name--small {
        font-size: 14px;
        font-weight: bold;
        color: #dd6179;
        margin-top: 5px;
        margin-left: 5px;
    }
    .staff-info__meta {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .meta__item {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin: 5px;
        background-color: #353148;
        padding: 15px;
        border-radius: 10px;
    }
    .label {
        color: #9797aa;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .content {
        text-align: left;
        font-size: 18px;
        font-weight: bold;
    }
    .data {
        position: relative;
    }
    .overlay {
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 40px;
        background-color: #2b273f;
    }
    .data-container {
        background-color: #ffffff;
        min-height: 50px;
        border-radius: 10px;
        margin: 15px 5px 5px;
        position: relative;
        z-index: 99;
    }
    .data-container__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .data__title {
        text-transform: uppercase;
        color: #2b273f;
        font-size: 16px;
        font-weight: bold;
        padding: 10px 10px 0;
    }
    .data-list {
        color: #2b273f;
        list-style-type: none;
        padding: 0 5px 5px;
        margin-top: 0;
    }
    .data-list__header {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
    .data-list__header .right span {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        color: #9797aa;
    }
    .data-item {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        border-top: 1px solid #e8e8e8;
        padding: 10px 0;
    }
    .data-item .left {
        font-size: 14px;
    }
    .data-item.bold {
        font-weight: bold;
        color: #353148;
    }
    .data-item .right {
        width: 20%;
        text-align: right;
    }
    .list-group-title {
        background-color: #e8e8e8;
        padding: 8px 10px;
        color: #9797aa;
        margin-top: 10px;
        font-weight: bold;
    }
    .rating-table {
        color: #000;
        padding: 5px;
        margin-top: 10px;
    }

    .rating-table .v2-table__cell {
        color: #2b273f;
        font-size: 14px;
    }

    .rating-table .v2-table__cell.v2-table__column-cell {
        color: #353148;
        font-weight: bold;
    }

    .cropper-area {
        width: 614px;
    }
</style>