<template>
    <router-view v-if="isAuthenticated"></router-view>
</template>

<script>
    export default {
        name: 'App',
        data() {
            return {
                isAuthenticated: true
            }
        }
    }
</script>

<style>
    html, body {
        min-height: 100%;
        font-family: 'Roboto', sans-serif;
        /* background-color: #e8e8e8; */
    }
</style>
