import axios from "axios";
import config from '../config';

const resources = "/api/statistic-service-quality-staff/ranking";
export function getReportData({fromDate, toDate, staffId, token}) {
    return axios.get(`${config.baseEndpoint}${resources}`, {
        params: {fromDate, toDate, staffId},
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}