import axios from 'axios';
import config from '../config';

const resources = '/api/customer-question';
export function fetchQuestion(params) {
    const { accessToken, ...ortherParams } = params;
    return axios.get(`${config.customerInsightEndpoint}${resources}`, {
        params: ortherParams,
        headers: { Authorization: `Bearer ${accessToken}` },
    });
}

export function postAnswer(params) {
    const { accessToken, ...ortherParams } = params;
    return axios.post(
        `${config.customerInsightEndpoint}${resources}`,
        ortherParams,
        {
            headers: { Authorization: `Bearer ${accessToken}` },
        },
    );
}

export function fetchCustomer(params) {
    const { accessToken, ...ortherParams } = params;
    return axios.get(`${config.customerEndpoint}/api/customer`, {
        params: ortherParams,
        headers: { Authorization: `Bearer ${accessToken}` },
    });
}

// export function fetchQuestion(params) {
//     const { accessToken, ...ortherParams } = params;
//     return axios.get(`${config.customerInsightEndpoint}${resources}`, {
//         params: ortherParams,
//         headers: { Authorization: `Bearer ${accessToken}` },
//     });
// }

// export function postAnswer(params) {
//     const { accessToken, ...ortherParams } = params;
//     return axios.post(
//         `${config.customerInsightEndpoint}${resources}`,
//         ortherParams,
//         {
//             headers: { Authorization: `Bearer ${accessToken}` },
//         }
//     );
// }

export function searchAddress(keyword, accessToken) {
    return axios.get(
        `${config.searchAddressEndpoint}/api/appcustomer/location/address-for-staff?input=${keyword}`,
        {
            headers: { Authorization: `Bearer ${accessToken}` },
        },
    );
}
