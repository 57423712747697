<template>
    <a-spin :spinning="isLoading">
        <div class="container">
            <div class="title">
                Anh hãy điền để bọn em phục vụ anh tốt hơn
            </div>
            <div class="content">
                <DropDown
                    v-for="question in dataQuestion"
                    :key="question.questionId"
                    :title="question.content"
                    @onToggle="updateAffix"
                >
                    <!-- type 1 -->
                    <!-- <div
                        v-if="
                            !question.isMultichoice &&
                                question.answer.length < 5
                        "
                        class="content-question"
                    > -->
                    <div
                        v-if="!question.isMultichoice"
                        class="content-question"
                    >
                        <a-radio-group
                            style="width: 100%"
                            @change="
                                (event) =>
                                    handleChangeRadio(
                                        question.questionId,
                                        event,
                                    )
                            "
                            :value="getValueRadio(question.questionId)"
                        >
                            <div
                                v-for="answer in question.answer"
                                :key="answer.id"
                            >
                                <a-radio
                                    :style="radioStyle"
                                    :value="JSON.stringify(answer)"
                                    class="radio-button"
                                >
                                    {{
                                        answer.type == ORTHER_TYPE
                                            ? 'Khác'
                                            : answer.content
                                    }}
                                    <a-input
                                        :ref="
                                            `${question.questionId}_${answer.id}`
                                        "
                                        v-if="answer.type == ORTHER_TYPE"
                                        :disabled="
                                            checkDisableInput(
                                                question.questionId,
                                                answer.id,
                                            )
                                        "
                                        :value="
                                            getValueInput(
                                                question.questionId,
                                                answer.id,
                                            )
                                        "
                                        @change="
                                            (event) =>
                                                handleChangeInput(
                                                    question.questionId,
                                                    answer.id,
                                                    event,
                                                )
                                        "
                                    />
                                </a-radio>
                            </div>
                        </a-radio-group>
                    </div>

                    <!-- type 2 -->
                    <!-- <div
                        v-if="
                            !question.isMultichoice &&
                                question.answer.length >= 5
                        "
                    >
                        <a-select
                            class="content-question-checkbox"
                            @change="
                                (event) =>
                                    handleChangeSelect(
                                        question.questionId,
                                        event
                                    )
                            "
                        >
                            <a-select-option
                                :value="answer.id"
                                v-for="answer in question.answer"
                                :key="answer.id"
                            >
                                {{ answer.content }}
                            </a-select-option>
                        </a-select>
                    </div> -->

                    <!-- type 3 -->
                    <div class="content-question" v-if="question.isMultichoice">
                        <a-checkbox
                            class="checkbox"
                            v-for="answer in question.answer"
                            :key="answer.id"
                            @change="
                                (event) =>
                                    handleChangeCheckBox(
                                        question.questionId,
                                        answer,
                                        event,
                                    )
                            "
                            :checked="
                                getValueCheckBox(question.questionId, answer.id)
                            "
                        >
                            {{
                                answer.type == ORTHER_TYPE
                                    ? 'Khác'
                                    : answer.content
                            }}
                            <a-input
                                style="margin-left: 3px"
                                :ref="`${question.questionId}_${answer.id}`"
                                v-if="answer.type == ORTHER_TYPE"
                                :disabled="
                                    checkDisableInput(
                                        question.questionId,
                                        answer.id,
                                    )
                                "
                                :value="
                                    getValueInput(
                                        question.questionId,
                                        answer.id,
                                    )
                                "
                                @change="
                                    (event) =>
                                        handleChangeInput(
                                            question.questionId,
                                            answer.id,
                                            event,
                                        )
                                "
                            />
                        </a-checkbox>
                    </div>
                </DropDown>

                <a-affix :offset-bottom="10" ref="affixRef">
                    <div class="footer">
                        <div class="button" @click="handleSendAnswer">
                            Cập nhật
                        </div>
                    </div>
                </a-affix>
            </div>
        </div>
    </a-spin>
</template>

<script>
import DropDown from '../components/customer-insight/DropDown';
import {
    fetchQuestion,
    postAnswer,
} from '../repository/customerInsightRepository';

const ORTHER_TYPE = 2;

// const fakeResponse = {
//     data: [
//         {
//             questionId: 1,
//             content: 'Anh có thích dịch vụ cắt móng tay không',
//             isMultichoice: false,
//             answer: [
//                 { id: 1, content: 'có', type: 1 },
//                 { id: 2, content: 'không', type: 1 },
//                 { id: 12, content: 'Khác', type: 2 },
//             ],
//         },
//         {
//             questionId: 2,
//             content: 'Anh muốn nhân viên phục vụ như thế nào',
//             isMultichoice: false,
//             answer: [
//                 { id: 3, content: 'Trò chuyện vui vẻ', type: 1 },
//                 { id: 4, content: 'Thư giãn yên tĩnh', type: 1 },
//             ],
//         },
//         {
//             questionId: 3,
//             content: 'Anh muốn Stylist cắt kiểu nào nào',
//             isMultichoice: false,
//             answer: [
//                 { id: 5, content: 'Kiểu cũ', type: 1 },
//                 { id: 6, content: 'Stylist tư vấn thêm', type: 1 },
//                 { id: 7, content: 'Đổi kiểu', type: 1 },
//             ],
//         },
//         {
//             questionId: 4,
//             content: 'Anh muốn dùng nước gì',
//             isMultichoice: true,
//             answer: [
//                 { id: 8, content: 'Mocktail chanh leo', type: 1 },
//                 { id: 9, content: 'Mocktail dâu tây', type: 1 },
//                 { id: 10, content: 'Khác', type: 2 },
//             ],
//         },
//     ],
// };

export default {
    components: { DropDown },

    data() {
        return {
            ORTHER_TYPE,
            radioStyle: {
                display: 'block',
                height: '30px',
                lineHeight: '30px',
            },
            isLoading: false,
            dataQuestion: [],
            listAnswer: [],
        };
    },

    created() {
        this.fetchQuestion();
    },

    methods: {
        async fetchQuestion() {
            this.isLoading = true;
            try {
                const {
                    os = 1,
                    customerId = 1,
                    accessToken = '',
                    token = '',
                } = this.$route.query;
                const params = {
                    os,
                    customerId,
                    accessToken: accessToken || token,
                };
                const response = await fetchQuestion(params);
                const { data } = response;
                this.dataQuestion = (data && data.data) || [];
                this.listAnswer = this.dataQuestion.map((question) => {
                    const hasAnswer = question.answer.filter(
                        (ans) => ans.isAnswered,
                    );
                    const answer = {
                        questionId: question.questionId,
                        listAnswer: [],
                    };
                    if (hasAnswer.length > 0) {
                        answer.listAnswer = hasAnswer.map((ans) => {
                            if (ans.type == ORTHER_TYPE) {
                                return {
                                    id: ans.id,
                                    content: ans.content,
                                };
                            }
                            return {
                                id: ans.id,
                            };
                        });
                    }
                    return answer;
                });
            } catch (error) {
                console.log(error);
                this.$message.error(
                    'Lấy danh sách câu hỏi thất bại, thử lại sau.',
                );
            } finally {
                this.isLoading = false;
            }
        },

        checkDisableInput(questionId, answerId) {
            const question = this.listAnswer.find(
                (ans) => ans.questionId == questionId,
            );
            if (question && question.listAnswer.length > 0) {
                const answer = question.listAnswer.find(
                    (ans) => ans.id == answerId,
                );
                return !answer;
            } else return true;
        },

        getValueInput(questionId, answerId) {
            const question = this.listAnswer.find(
                (ques) => ques.questionId == questionId,
            );
            if (question) {
                const answer = question.listAnswer.find(
                    (ans) => ans.id == answerId,
                );
                return (answer && answer.content) || '';
            }
            return '';
        },

        handleChangeInput(questionId, answerId, event) {
            const question = this.listAnswer.find(
                (ques) => ques.questionId == questionId,
            );
            const answer = question.listAnswer.find(
                (ans) => ans.id == answerId,
            );
            answer.content = event.target.value;
        },

        getValueRadio(questionId) {
            const answerSelect = this.listAnswer.find(
                (ques) => ques.questionId === questionId,
            ).listAnswer;

            const question = this.dataQuestion.find(
                (ques) => ques.questionId === questionId,
            );
            if (answerSelect.length === 0) {
                const answer = question.answer.find((ans) => ans.isAnswered);
                if (answer) return JSON.stringify(answer);
                return '';
            } else {
                const IdAnswer = answerSelect[0].id;
                const answer = question.answer.find(
                    (ans) => ans.id === IdAnswer,
                );
                return JSON.stringify(answer);
            }
        },

        handleChangeRadio(questionId, event) {
            const { value } = event.target;
            const answer = JSON.parse(value);
            this.listAnswer = this.listAnswer.map((item) => {
                if (item.questionId != questionId) return item;
                else if (answer.type === ORTHER_TYPE) {
                    const question = this.dataQuestion.find(
                        (ques) => ques.questionId === questionId,
                    );
                    const answerRes = question.answer.find(
                        (ans) => ans.isAnswered,
                    );
                    let result = {
                        questionId,
                        listAnswer: [
                            {
                                id: answer.id,
                                content: '',
                            },
                        ],
                    };
                    if (answerRes && answerRes.id == answer.id) {
                        result.listAnswer = [
                            {
                                id: answer.id,
                                content: answerRes.content,
                            },
                        ];
                    }
                    this.$nextTick(function() {
                        this.$refs[`${questionId}_${answer.id}`][0].focus();
                    });

                    return result;
                } else
                    return {
                        questionId,
                        listAnswer: [
                            {
                                id: answer.id,
                            },
                        ],
                    };
            });
        },

        getValueCheckBox(questionId, answerId) {
            const answerSelect = this.listAnswer.find(
                (ques) => ques.questionId === questionId,
            ).listAnswer;

            // const question = this.dataQuestion.find(
            //     (ques) => ques.questionId === questionId
            // );
            // if (answerSelect.length === 0) {
            //     const answer = question.answer.find(
            //         (ans) => ans.id == answerId
            //     );
            //     if (answer) return answer.isAnswered;
            //     return false;
            // } else {
            const answer = answerSelect.find((ans) => ans.id === answerId);
            if (answer) return true;
            return false;
            // }
        },

        handleChangeCheckBox(questionId, answer, event) {
            const isCheck = event.target.checked;
            if (isCheck) {
                this.listAnswer = this.listAnswer.map((item) => {
                    if (item.questionId != questionId) return item;
                    else if (answer.type === ORTHER_TYPE) {
                        const question = this.dataQuestion.find(
                            (ques) => ques.questionId === questionId,
                        );
                        const answerRes = question.answer.find(
                            (ans) => ans.id == answer.id,
                        );
                        if (answerRes && answerRes.isAnswered) {
                            item.listAnswer.push({
                                id: answer.id,
                                content: answerRes.content,
                            });
                        } else {
                            item.listAnswer.push({
                                id: answer.id,
                                content: '',
                            });
                        }
                        this.$nextTick(function() {
                            this.$refs[`${questionId}_${answer.id}`][0].focus();
                        });
                    } else
                        item.listAnswer.push({
                            id: answer.id,
                        });
                    return item;
                });
            } else {
                this.listAnswer = this.listAnswer.map((item) => {
                    if (item.questionId != questionId) return item;
                    else {
                        item.listAnswer = item.listAnswer.filter(
                            (item) => item.id !== answer.id,
                        );
                        return item;
                    }
                });
            }
        },

        updateAffix() {
            if (this.$refs.affixRef) {
                this.$refs.affixRef.updatePosition();
                setTimeout(() => {
                    this.$refs.affixRef.updatePosition();
                }, 200);
            }
        },

        async handleSendAnswer() {
            this.isLoading = true;
            try {
                const {
                    os = 1,
                    customerId = 1,
                    accessToken = '',
                    token = '',
                } = this.$route.query;
                const params = {
                    customerId: parseInt(customerId),
                    os: parseInt(os),
                    accessToken : accessToken || token,
                    // answer: this.listAnswer.filter(
                    //     (answer) => answer.listAnswer.length > 0
                    // ),
                    answer: this.listAnswer,
                };
                if (params.answer.length === 0) {
                    this.$message.success('Cập nhật thành công');
                    return;
                }
                // eslint-disable-next-line no-unused-vars
                const response = await postAnswer(params);
                this.$message.success('Cập nhật thành công');
                this.fetchQuestion();
            } catch (error) {
                console.log(error);
                this.$message.error('Gửi câu trả lời thất bại');
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped>
* {
    font-family: 'Be Vietnam Pro', sans-serif;
    color: #000000;
}

.container {
    min-height: 100vh;
    width: 100%;
}

.title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #424242;
    margin-top: 10px;
    padding-bottom: 6px;
    border-bottom: 2px solid #ffcc33;
}

.content {
    padding: 10px;
}

.content-question {
    padding: 5px 15px;
    overflow-x: scroll;
}

.content-question-checkbox {
    margin-top: 10px;
    width: 100%;
    margin-bottom: 15px;
}

.checkbox {
    width: 100%;
    margin-bottom: 10px;
    min-height: 24px;
}

.ant-checkbox-wrapper {
    /* height: 30px; */
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
}

input {
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #e8e8e8;
}

input:focus {
    box-shadow: none;
    border-bottom: 1px solid #e8e8e8;
}

.button {
    text-align: center;
    background: #ffcc33;
    border-radius: 5px;
    padding: 6px 20px;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    width: 100%;
    margin: auto;
}

.button:hover {
    opacity: 0.7;
}

.footer {
    padding: 20px 5px 5px 5px;
}
</style>

<style>
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ffcc33;
    border-color: #ffcc33;
}
.ant-radio-checked .ant-radio-inner {
    border-color: #ffcc33;
}
.ant-radio-inner::after {
    background-color: #ffcc33;
}
/* .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid black;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
} */

.radio-button {
    display: flex !important;
    align-items: center;
    margin-bottom: 5px;
}

.radio-button > span:last-child {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 0px;
}
</style>
