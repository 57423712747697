import Vue from "vue";
import Router from "vue-router";
import App from "../App";
import HelloWorld from "@/components/HelloWorld";
import StylistReport from "@/components/StylistReport";
import UploadBookingPhotos from "@/components/UploadBookingPhotos";
import CustomerInsight from "../pages/CustomerInsight";
import ReportSalary from "../pages/Salary/ReportSalary.vue";
import CustomerInsightSuccess from "../pages/CustomerInsightSuccess.vue";
import CustomerInsightFavorite from "../pages/CustomerInsightFavorite.vue";
import LuckyWheel from "../pages/LuckyWheel/Wheel.vue";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import Profile from "../pages/Profile/Profile.vue";
import Mission from "../pages/Mission/Mission.vue";
import Friend from "../pages/Friend/Friend.vue";
import News from "../pages/News";
import NewsDetail from "../pages/News/detail.vue";
import Page404 from "../pages/Error/Page404";
import Page401 from "../pages/Error/Page401";
import Page500 from "../pages/Error/Page500";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      component: App,
      children: [
        {
          path: "",
          component: HelloWorld,
        },
        {
          path: "report",
          component: StylistReport,
        },
        {
          path: "upload-booking-photos",
          component: UploadBookingPhotos,
        },
        {
          path: "customer-insight",
          component: CustomerInsight,
        },
        {
          path: "success",
          component: CustomerInsightSuccess,
        },
        {
          path: "interest",
          component: CustomerInsightFavorite,
        },
        {
          path: "report-salary",
          component: ReportSalary,
        },
        {
          path: "lucky-wheel",
          component: LuckyWheel,
        },
        {
          path: "30shine-news/",
          component: News,
        },
        {
          path: "30shine-news/detail/:id",
          component: NewsDetail,
        },
      ],
    },

    {
      path: "/we-up/",
      component: DefaultLayout,
      children: [
        {
          path: "lucky-wheel",
          component: LuckyWheel,
        },
        {
          path: "mission",
          component: Mission,
        },
        {
          path: "friend",
          component: Friend,
        },
        {
          path: "",
          component: Profile,
        },
      ],
    },
    {
      path: "/404",
      component: Page404,
    },
    {
      path: "/401",
      component: Page401,
    },
    {
      path: "/500",
      component: Page500,
    },
    {
      path: "/:catchAll(.*)",
      redirect: "404",
    },
  ],
  mode: "history",
  linkActiveClass: "active", // active class for non-exact links.
  linkExactActiveClass: "exact-active", // active class for *exact* links.
});
