<template>
  <div>
    <a-pop-confirm placement="bottom">
      <a-button @click="openDateModal" :disabled="disabledCalendar"> <a-icon :type="'calendar'"/></a-button>
      <a-modal v-model="dateSelectVisible" title="" @ok="handleCloseCalendar">
        <a-calendar
          :fullscreen="false"
          @select="(event) => selectDate(event)"
          @panelChange="(event) => selectDate(event)"
          :mode='year'
        />
      </a-modal>
    </a-pop-confirm>
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/vi";
export default {
  name: "DateSelectModal",
  props: {
    questionId: Number,
    answerId: Number,
    disabledCalendar: Boolean
  },
  components: {},
  data() {
    return {
      questionId: this.questionId ?? 0,
      answerId: this.answerId ?? 0,
      dateSelectVisible: false,
      dateValue: "",
    };
  },
  methods: {
    openDateModal() {
      this.dateSelectVisible = true;
    },

    selectDate(event) {
      this.dateValue = moment(event).format("DD/MM/YYYY");
    },
    handleCloseCalendar() {
      //passing value sang bên ngoài
      console.log("dateValue được chọn ở câu " + this.questionId, this.dateValue)
      this.dateSelectVisible = false; 
      this.$emit("onCalendarConfirm", this.dateValue);
    },
  },
  created() {},
  watch(){}
};
</script>

<style scoped>

</style>
