<template>
  <a-spin :spinning="isLoading || isFirstLoading">
    <section class="vue-winwheel" v-if="!showPageError">
      <a-affix :offset-top="top">
        <div class="staff-info">
          <div>
            <a-avatar size="large" icon="user" />
            <!-- <span v-else class="avatar">
              <img :src="getUser.avatar" alt="" />
            </span> -->
          </div>
          <div class="information" v-if="getUser.name">
            <div class="name">{{ getUser.name }}</div>
            <div class="description-wrapper">
              <div>Danh hiệu cá nhân</div>
              <div class="point">
                {{ getUser.point }}
                <img src="../assets/star-black.png" alt="" />
              </div>
            </div>
          </div>
          <a-card>
            <div class="button-wrapper">
              <div
                class="button-item"
                v-for="(item, i) in config"
                :key="i"
                v-on:click="onGoClick(item.path)"
                :class="item.path === $route.path ? 'highlighted' : ''"
              >
                <a-button shape="circle">
                  <!-- <a-icon  type="schedule" /> -->
                  <img
                    style="width: 24px"
                    :src="
                      item.path === $route.path ? iconBoxWhite : iconBoxBlack
                    "
                    v-if="item.key === 'mission'"
                  />
                  <img
                    style="width: 24px"
                    :src="
                      item.path === $route.path
                        ? iconProfileWhite
                        : iconProfileBlack
                    "
                    v-else-if="item.key === ''"
                  />
                  <a-icon v-else-if="item.key === 'lucky-wheel'" type="gift" />
                  <a-icon v-else type="search" />
                </a-button>
                <span class="item-text"> {{ item.label }}</span>
              </div>
            </div>
          </a-card>
        </div>
        <div
          v-if="$route.path == '/we-up/lucky-wheel'"
          class="background-white"
        ></div>
        <div v-else class="background-gray"></div>
      </a-affix>
      <router-view></router-view>
    </section>
    <Page401 v-else-if="pageErrorCode === 401" />
    <Page500 v-else-if="pageErrorCode === 500" />
  </a-spin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Page401 from "../pages/Error/Page401";
import Page500 from "../pages/Error/Page500";

const config = [
  {
    key: "",
    path: "/we-up",
    label: "Thành tích",
  },
  {
    key: "mission",
    path: "/we-up/mission",
    label: "Nhiệm vụ",
  },
  {
    key: "lucky-wheel",
    path: "/we-up/lucky-wheel",
    label: "Q.Thưởng",
  },
  {
    key: "friend",
    path: "/we-up/friend",
    label: "Bạn bè",
  },
];
export default {
  name: "default-layout",
  components: { Page401, Page500 },
  data() {
    return {
      config,
      top: 0,
      isLoading: false,
      isFirstLoading: false,
      showPageError: false,
      pageErrorCode: 0,
      iconProfileBlack: require("./profile-icon-black.png"),
      iconProfileWhite: require("./profile-icon-white.png"),
      iconBoxBlack: require("./mission-icon-black.png"),
      iconBoxWhite: require("./mission-icon-white.png"),
    };
  },

  computed: {
    ...mapGetters("authenticate", ["getUser"]),
  },

  methods: {
    ...mapActions("authenticate", ["setUser"]),
    onGoClick: function (path) {
      const { accessToken, token } = this.$route.query;
      const accessTokenCp = accessToken || token;
      this.$router.push({
        path: `${path}`,
        query: { accessToken: `${accessTokenCp}` },
      });
    },
    handleApiFetchError(error) {
      if (error && error.response && error.response.status == 401) {
        this.showPageError = true;
        this.pageErrorCode = 401;
      }
    }
  },

  async created() {
    try {
      const { accessToken, token } = this.$route.query;
      const accessTokenCp = accessToken || token;
      await this.setUser(accessTokenCp);
    } catch (e) {
      this.handleApiFetchError(e);
    }
  }
};
</script>

<style lang="scss">
.staff-info {
  .ant-card {
    position: absolute;
    height: auto;
    top: 65px;
    margin: 0 15px;
    left: 0;
    right: 0;
    text-align: center;
    overflow: hidden;
    border-radius: 10px;
    .ant-card-body {
      padding: 0;
      .button-wrapper {
        display: flex;
        padding: 15px;
        justify-content: space-around;
        .button-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex: 1;
          .item-text {
            margin-top: 8px;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
            font-style: normal;
            font-family: 'Be Vietnam Pro', sans-serif;
          }
          .ant-btn {
            width: 44px;
            height: 44px;
            font-size: 24px;
            align-items: center;
            display: flex;
            justify-content: center;
            outline: none;
            border-color: #000;
          }
        }
        .highlighted {
          .ant-btn {
            background-color: #000;
            color: #fff;
          }
          .item-text {
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
.vue-winwheel {
  .staff-info {
    background: #ffcc33;
    border-radius: 0px;
    font-family: 'Be Vietnam Pro', sans-serif;
    color: #333333;
    padding: 8px 10px;
    display: flex;
    flex-direction: row;
    height: 100px;

    .avatar {
      img {
        width: 40px;
        height: 40px;
        object-fit: contain;
        border-radius: 50%;
        overflow: hidden;
      }
    }

    .information {
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      text-align: left;
      padding-left: 10px;
      width: 100%;
      .name {
        font-weight: bold;
        font-size: 16px;
        text-transform: uppercase;
      }
      .description-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px
      }
      .point {
        font-weight: 500;
        font-size: 12px;
        display: flex;
        align-items: center;
        margin-right: 10px;
        img {
          margin-left: 4px;
        }
      }
    }

    .btn-exchange {
      flex: 1;
      text-align: right;
      margin: auto;

      .btn-turn {
        background: #161616;
        border: none;
        border-radius: 5px;
        font-family: 'Be Vietnam Pro', sans-serif;
        font-size: 12px;
        text-align: center;
        color: #ffffff;
        width: 80px;
        padding: 3px;
        cursor: pointer;
        margin: auto;

        &:focus {
          outline: none;
        }
      }
    }
  }
  .background-white {
    height: 61px;
    background-color: #fff;
  }
  .background-gray {
    height: 61px;
    background-color: #f3f3f3;
  }
}
.ant-modal {
  top: 0;
}
</style>