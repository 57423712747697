<template>
  <a-spin :spinning="isLoading">
    <div class="profile-wrapper">
      <div class="title-wrapper">
        <div class="title-content">Tất cả thành tích</div>
      </div>
      <a-card
        v-for="(item, key) in detailStaff.grades"
        :key="key"
        :title="item.category"
        :bordered="true"
      >
        <div class="list-wrapper">
          <div
            v-for="(item, key) in item.data"
            :key="key"
            v-on:click="onViewDetail(item)"
            class="item"
          >
            <img class="item-image" :src="item.icon" />
            <span class="item-text">{{ item.title }}</span>
          </div>
        </div>
      </a-card>
      <a-drawer
        placement="bottom"
        :visible="detailVisible"
        class="detail-drawer"
        @close="onClose"
        height="100%"
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 50px;
          "
          class="drawer-wrapper"
        >
          <img
            style="
              width: 100%;
              height: 100%;
              max-width: 162px;
              max-height: 162px;
              margin-bottom: 59px;
            "
            class="item-image"
            :src="prosper.icon"
          />
          <span
            :style="[
              prosper.active != 0
                ? {
                    'font-weight': 'bold',
                    'font-size': '50px',
                    'text-align': 'center',
                    color: '#ef5350',
                    'line-height': '73px',
                    'font-style': 'italic',
                    'margin-bottom': '9px',
                  }
                : {
                    'font-weight': 'bold',
                    'font-size': '50px',
                    'text-align': 'center',
                    color: '#d1d1d1',
                    'line-height': '73px',
                    'font-style': 'italic',
                    'margin-bottom': '9px',
                  },
            ]"
            class="item-title"
            >{{ prosper.title }}</span
          >
          <div style="text-align: center">
            <span
              style="
                font-weight: 400;
                font-size: 20px;
                text-align: center;
                line-height: 30px;
                font-style: normal;
              "
              v-for="(item, key) in prosper.subTitle"
              :key="key"
              class="item-description"
              >{{ item }}
              <span v-if="key != prosper.subTitle.length - 1">, </span>
            </span>
          </div>
        </div>
      </a-drawer>
    </div>
  </a-spin>
</template>
<script>
  import {mapActions, mapGetters} from "vuex";
const data = [
  {
    title: "Cá nhân",
  },
  {
    title: "Tập thể",
  },
];
export default {
  name: "profile",
  data() {
    return {
      isLoading: false,
      data,
      detailVisible: false,
      detailStaff: {},
      prosper: {},
    };
  },
  computed: {
    ...mapGetters("authenticate", ["getUser"]),
  },
  watch: {
    getUser(value) {
      this.detailStaff = value;
    },
  },
  async created() {
    try {
      const { accessToken = "", token = "" } = this.$route.query;
      const accessTokenCp = accessToken || token;
      await this.setUser(accessTokenCp);
      if (this.getUser.name) {
        this.detailStaff = this.getUser;
      }
    } catch (e) {
      this.handleApiFetchError(e);
    }
  },
  methods: {
    ...mapActions("authenticate", ["setUser"]),
    onViewDetail(node) {
      this.prosper = node;
      this.detailVisible = true;
    },
    onClose() {
      this.detailVisible = false;
    },
  },
};
</script>

<style lang="scss">
.profile-wrapper {
  background-color: #f3f3f3;
  height: auto;
  min-height: 80vh;
  .title-wrapper {
    display: inline-block;
    margin: 8px 0;
    margin-left: 15px;
    .title-content {
    font-weight: 500;
    font-style: normal;
    font-family: 'Be Vietnam Pro', sans-serif;;
    line-height: 21px;
    font-size: 20px;
    color: #000;
    }
  }
  .ant-card {
    margin: 10px 15px;
    border-radius: 10px;
    .ant-card-bordered {
      border: none;
    }
    .ant-card-head {
      padding: 0 5px;
    }
    .ant-card-body {
      padding: 5px;
      .list-wrapper {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 10px;
          width: calc(100% /3);
          .item-image {
            max-width: 90px;
            max-height: 90px;
            margin-bottom: 10px;
            width: 100%;
            height: 100%;
            object-fit: fill;
          }
          .item-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            font-style: normal;
            text-align: center;
            font-family: 'Be Vietnam Pro', sans-serif;;
          }
        }
      }
    }
    .ant-card-head {
      border-bottom: none;
      .ant-card-head-title {
        font-weight: 600;
        font-style: normal;
        font-family: 'Be Vietnam Pro', sans-serif;;
        line-height: 21px;
        font-size: 18px
      }
    }
  }
  .detail-drawer {
    .ant-drawer-header {
      border-bottom: none;
    }
  }
}
@media (max-width: 320px) {
  .profile-wrapper {
    .ant-card {
      .ant-card-body {
      .list-wrapper {
        .item {
          .item-text {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            font-style: normal;
            text-align: center;
            font-family: 'Be Vietnam Pro', sans-serif;;
          }
        }
      }
     }
    }
  }
}
</style>

