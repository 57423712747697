<template>
  <div class="friend-wrapper">
    <div class="friend-message">
      Tính năng sẽ được cập nhật trong thời gian sớm nhất bạn quay lại sau nha
      !!!
    </div>
  </div>
</template>
<script>
export default {
  name: "friend",
};
</script>

<style lang="scss">
.friend-wrapper {
  height: 300px;
  display: flex;
  align-items: center;
  margin: 0 50px;
  text-align: center;
  .friend-message {
    font-weight: 500;
    line-height: 21px;
    font-family: 'Be Vietnam Pro', sans-serif;
    font-size: 13px;
    color: #000;
    font-style: italic
  }
}

</style>
